import {EuiBasicTable, EuiHealth, EuiDescriptionList} from "@elastic/eui";
import {useState} from "react";
import moment from "moment/moment";

import {useHistory} from "react-router-dom";


const UniqLocationTable = ({uniqLocations}) => {
    const history = useHistory();


    const columns = [{
        field: 'name', name: 'Name', sortable: true, 'data-test-subj': 'nameCell', width: '20%'
    }, {
        field: 'start_monitoring_moment',
        name: 'Start of monitoring period',
        sortable: true,
        dataType: 'date',
        'data-test-subj': 'xCell',
        width: '30%',
        render: item => {
            return `${item !== undefined ? moment(item).format("YYYY-MM-DD") : "Not defined"}`
        }
    }, {
        field: 'stop_monitoring_moment',
        name: 'End of monitoring period',
        sortable: false,
        'data-test-subj': 'xCell',
        width: '30%',
        render: item => `${item !== undefined ? moment(item).format("YYYY-MM-DD") : "Not defined"}`
    }, {
        field: 'active',
        name: 'Active',
        sortable: true,
        'data-test-subj': 'xCell',
        width: '20%',
        render: item => {

            return (
                <EuiHealth color={item ? 'success' : 'danger'}>{item ? 'Active' : 'Inactive'}</EuiHealth>)
        }
    },
        {
            field: 'assigned_uniq',
            name: 'Id of assigned UniQ',
            sortable: true,
            'data-test-subj': 'xCell',
            width: '20%',
            render: item => item === undefined ? 'N/A' : `${item['id']}`
        }
    ];

    const getRowProps = (item) => {
        const {id} = item;
        return {
            'data-test-subj': `row-${id}`, className: 'customRowClass', onClick: () => {
                history.push(`/uniq_locations/${item.id}`)
            },
        };
    };

    const getCellProps = (item, column) => {
        const {id} = item;
        const {field} = column;
        return {
            className: 'customCellClass', 'data-test-subj': `cell-${id}-${field}`, textOnly: true,
        };
    };

    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');


    const sorting = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    };

    const onTableChange = ({page, sort}) => {
        if (sort) {
            const {field: sortField, direction: sortDirection} = sort;
            setSortField(sortField);
            setSortDirection(sortDirection);
        }
    };

    return (
        <EuiBasicTable
            items={uniqLocations}
            columns={columns}
            sorting={sorting}
            itemId="id"
            rowProps={getRowProps}
            onChange={onTableChange}
            cellProps={getCellProps}

        />
    );
}

export default UniqLocationTable
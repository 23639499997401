import {EuiSelectable} from "@elastic/eui"
import {useEffect, useState} from "react";

const UniqSelection = ({uniqs, selectedUniqId, onChange}) => {

    const [options, setOptions] = useState([]);

    const reset = (selectedUniqId) => {
        setOptions(uniqs.map((uniq, i) => {
                return {
                    label: uniq.id.toString(),
                    id: uniq.id,
                    checked: uniq.id === selectedUniqId ? 'on' : undefined
                }
            }
        ).sort((a, b) => a.id > b.id ? 1 : -1));
    }

    useEffect(() => {
        reset(selectedUniqId)
    }, [selectedUniqId]);

    return (
        <EuiSelectable
            searchable
            searchProps={{
                'data-test-subj': 'selectableSearchHere',
            }}
            options={options}
            singleSelection='always'

            onChange={(newOptions) => {
                let selected = undefined

                for (const option of newOptions) {
                    if (option.checked === 'on') {
                        selected = option.id
                    }

                }
                onChange(selected)
            }}
        >
            {(list, search) => (
                <>
                    {search}
                    {list}
                </>
            )}
        </EuiSelectable>
    );
}

export default UniqSelection;
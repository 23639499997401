import React from "react"

import {
    EuiFlexGroup, EuiFlexItem,
    EuiSpacer, EuiText,
} from '@elastic/eui';
import Segment from "../Segment";
import GeometryEditor from "../GeometryEditor";
import UniqAssignment from "../UniqAssignment";

const UniqLocationUpdateSection = ({location}) => {


    return (
        <Segment title="Settings">
            <EuiFlexGroup direction="row">
                <EuiFlexItem>
                    <EuiText><h3>Update the geometry</h3></EuiText>
                    <EuiSpacer size="m"/>
                    <GeometryEditor location={location}/>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiText><h3>Assign an UniQ to this location</h3></EuiText>
                    <EuiSpacer size="m"/>
                    <UniqAssignment location={location}/>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Segment>
    )
}

export default UniqLocationUpdateSection
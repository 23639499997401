import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiEmptyPrompt,
    EuiPageBody,
    EuiHorizontalRule,
    EuiSelectable,
    EuiIcon,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiSpacer,
    EuiForm,
    EuiCallOut,
    EuiFormRow,
    EuiButton,
    EuiPageHeader, EuiLoadingLogo,
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import {useProcessCalls} from "../../context/processCalls";
import ParameterForm from "../../components/ParameterForm";

const CreatePostProcessPage = (props) => {
    const history = useHistory();

    const [services, setServices] = useState(undefined);
    const [selectedService, setSelectedService] = useState(undefined)
    const [values, setValues] = useState({
        waveform_range: {...props.match.params}
    })
    const [name, setName] = useState("")
    const [sending, setSending] = useState(false)
    const [serviceLoadingError, setServiceLoadingError] = useState(undefined);
    const [serviceSendingError, setServiceSendingError] = useState(undefined);


    const {getServices, createProcessCall} = useProcessCalls();

    const send = (name, values) => {
        setSending(true)
        createProcessCall({
            name: name,
            serviceName: selectedService.name,
            parameters: values
        }).then((createdProcessCallResponse => {
            history.push(`/postprocesses/${createdProcessCallResponse.id}`)
        })).catch((e) => {
            setSending(false)
            setServiceSendingError(e)
        });

    }

    const generateOptionsFromServices = (services) => {
        return services.map(service => {
            return {
                ...service,
                label: service.name,
                checked: selectedService !== undefined && service.name === selectedService.name ? 'on' : undefined,
            }
        })
    }

    useEffect(() => {
        getServices().then((services) => {
            setSelectedService(services[0])
            setServices(services)
        })
            .catch(_ => {
                setServiceLoadingError(true)
            })
    }, [])

    const valid = selectedService !== undefined && selectedService.parameters.every(param => {
        if (param.nullable !== true) {
            return values[param.name] !== undefined
        }
        return true;
    }) && name !== undefined && name !== "";

    return (
        <EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType={'visualizeApp'}
                pageTitle="Run a new Data Processing job"
                description="Generate new data by using one of the available services."
                breadcrumbs={[
                    {
                        text: (
                            <>
                                <EuiIcon size="s" type="arrowLeft"/> Return
                            </>
                        ),
                        color: 'primary',
                        'aria-current': false,
                        href: '#',
                        onClick: (e) => e.preventDefault(),
                    }]}
            />
            {serviceLoadingError === undefined && services === undefined && (
                <EuiPageContent verticalPosition="center"
                                horizontalPosition="center"
                                paddingSize="none"
                                hasShadow={false}
                >
                    <EuiEmptyPrompt
                        icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                        title={<h2>Loading services</h2>}
                    />
                </EuiPageContent>
            )}
            {serviceLoadingError !== undefined && (
                <EuiPageContent verticalPosition="center"
                                horizontalPosition="center"
                                paddingSize="none"
                                hasShadow={false}
                >
                    Error :(
                </EuiPageContent>
            )}
            {services !== undefined && (
                <EuiPageContent
                    paddingSize="none"
                    color="transparent"
                    borderRadius="none"
                >
                    <EuiPageContentBody restrictWidth={'75%'}>
                        <EuiForm>
                            <EuiFormRow label="Service to use">
                                <EuiSelectable
                                    aria-label="Searchable example"
                                    searchable
                                    searchProps={{
                                        'data-test-subj': 'selectableSearchHere',
                                    }}
                                    options={generateOptionsFromServices(services)}
                                    singleSelection={"always"}
                                    allowExclusions={false}
                                    onChange={(newOptions) => {
                                        for (const key in newOptions) {
                                            if (newOptions[key].checked === 'on') {
                                                setSelectedService(newOptions[key])
                                            }
                                        }
                                    }}
                                >
                                    {(list, search) => (<>
                                        {list}
                                        {search}
                                    </>)
                                    }
                                </EuiSelectable>
                            </EuiFormRow>
                            <EuiHorizontalRule/>
                            <EuiFormRow label="Name">
                                <EuiFieldText
                                    placeholder={"Please provide a descriptive name for the job"}
                                    value={name || ""}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </EuiFormRow>
                            <ParameterForm values={values}
                                           parameters={selectedService.parameters}
                                           onChange={(values) => setValues(values)}/>
                        </EuiForm>
                        <EuiSpacer size="m"/>
                        {serviceSendingError &&
                            <>
                                <EuiCallOut title="Sorry, there was an error" color="danger" iconType="alert">Please
                                    alert
                                    the service administrator and try again later.</EuiCallOut>
                                <EuiSpacer size="m"/>
                            </>}
                        {!sending && <EuiButton type='' isDisabled={!valid} onClick={() => {
                            send(name, values)
                        }}>Run!</EuiButton>}
                        {sending && <EuiLoadingSpinner size="m"/>}

                    </EuiPageContentBody>
                </EuiPageContent>
            )}
        </EuiPageBody>)


}


export default CreatePostProcessPage;

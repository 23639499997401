import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiErrorBoundary,
    EuiFlyout,
    EuiForm,
    EuiButton,
    EuiFormRow,
    EuiSelectable,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiSpacer,
    EuiButtonEmpty,
    EuiFlyoutHeader,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiAccordion
} from "@elastic/eui";
import React, {useEffect, useState} from "react";
import {useQBF} from "../../context/qbf";
import EuiTextAsNumberField from "../EuiTextAsNumberField";


const QBFSettingsFlyOut = (props) => {

    const {getSettings} = useQBF();


    // Flyout / Setting setup
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [qbfSettings, setQBFSettings] = useState(undefined);
    const [qbfSettingsLoading, setQBFSettingsLoading] = useState(true);
    const [qbfSettingsError, setQBFSettingsError] = useState(false);

    // Settings Flyout state
    const [selectedQBFSettingId, setSelectedQBFSettingId] = useState(undefined)
    const [currentSettings, setCurrentSettings] = useState(undefined)
    const [currentDisplay, setCurrentDisplay] = useState(undefined)
    const [validationErrors, setValidationErrors] = useState({})
    const [edited, setEdited] = useState(false);

    // Output upwards
    const [cleanSettings, setCleanSettings] = useState(undefined);

    const PARAMETERS = [
        ["NUMBER", "nfft"],
        ["NUMBER", "arrival_time_threshold"],
        ["NUMBER", "qbf_algorithm"],
        ["NUMBER", "wave_speed_a0"],
        ["NUMBER", "wave_speed_s0"],
        ["NUMBER", "speed_variation"],
        ["NUMBER", "cross_correlation_algorithm"],
        ["NUMBER", "dispersion_correction_algorithm"],
        ["NUMBER", "wave_speed"],
        ["NUMBER", "maximum_allowed_time_error"],
        ["NUMBER", "thresh"],
        ["NUMBER", "stft_center_frequency"],
        ["NUMBER", "poisson_ratio"],
        ["NUMBER", "minimal_snr"],
        ["NUMBER", "stiffener_correction_x"],
        ["NUMBER", "stiffener_correction_y"],
        ["NUMBER", "stiffener_correction_factor"],
        ["NUMBER", "weight_factor_correction_matrix"],
        ["NUMBER", "grid_size"],
        ["NUMBER", "pre_trigger_samples"],
        ["NUMBER", "time_step"],
        ["NUMBER", "wavelet_length"],
        ["NUMBER", "minimal_correlation_factor"],
        ["NUMBER", "resolution_coef"],
        ["NUMBER", "resolution"],
        ["NUMBER", "amp_corrct_key"],
        ["NUMBER", "find_minimum_error_algorithm"],
        ["NUMBER", "correlation_factor_power"],
        ["NUMBER", "stft_overlap_ratio"],
        ["NUMBER", "amplitude_coefficient_windowing"],
    ]


    useEffect(() => {
        setQBFSettingsLoading(true);
        getSettings().then(settings => {
            setQBFSettingsLoading(false);
            setCurrentSettings(settings[0])
            setCurrentDisplay(settings[0])
            setSelectedQBFSettingId(settings[0].id)
            setQBFSettings(settings);
        }).catch(() => setQBFSettingsError(true))
    }, [])

    const onChange = props.onChange;
    const FlyoutFormId = useGeneratedHtmlId({prefix: 'FlyoutForm'});

    const handleChange = (parameter, display, parsed) => {
        setEdited(true)
        setCurrentSettings({
            ...currentSettings, [parameter]: parsed,
        })
        setCurrentDisplay({
            ...currentDisplay, [parameter]: display
        })
    }


    const closeFlyout = () => setIsFlyoutVisible(false);
    const form = (onChange, selectedQBFSettingId) => {
        if (qbfSettings === undefined) return
        return (<EuiFlexGroup>
            <EuiFlexItem grow={3}>
                <EuiSelectable
                    aria-label="Single selection example"
                    options={qbfSettings.map(setting => {
                        return {
                            checked: setting.id === selectedQBFSettingId ? "on" : undefined,
                            label: `${setting.id === selectedQBFSettingId && edited ? "*" : ""}${setting.name}`, ...setting
                        }
                    })}
                    onChange={(newOptions) => {
                        const selected = newOptions.filter(option => option.checked === "on")[0]
                        setEdited(false)
                        setCurrentSettings(selected);
                        setCurrentDisplay(selected)
                        setSelectedQBFSettingId(selected.id)
                    }}
                    singleSelection="always"
                    allowExclusions={false}
                    listProps={{bordered: true}}
                >
                    {(list) => list}
                </EuiSelectable>
            </EuiFlexItem>
            <EuiFlexItem grow={7}>
                <EuiForm id={FlyoutFormId} component="form" isInvalid={Object.keys(validationErrors).length > 0}
                         onSubmit={() => {
                             const onlyParams = PARAMETERS.reduce((obj, [TYPE, curr]) => {
                                 return {...obj, [curr]: currentSettings[curr]}
                             }, {})
                             setCleanSettings(onlyParams)
                             onChange(onlyParams)
                             closeFlyout()
                         }}>
                    {PARAMETERS.map(([TYPE, parameter], index) => (<EuiFormRow label={parameter} key={index}
                                                                               isInvalid={validationErrors[parameter] !== undefined}
                                                                               error={validationErrors[parameter]}>
                        <>
                            {TYPE === 'FLOAT_LIST' && (<EuiFieldText placeholder={""}
                                                                     value={currentDisplay[parameter]}
                                                                     onChange={(e) => {
                                                                         handleChange(parameter, e.target.value, e.target.value)
                                                                     }}/>)}
                            {TYPE === 'NUMBER' && (<EuiTextAsNumberField value={currentDisplay[parameter]}
                                                                         error={validationErrors[parameter]}
                                                                         onChange={(display, parsed) => {
                                                                             handleChange(parameter, display, parsed)
                                                                         }}
                                                                         onValidationError={(error) => {
                                                                             setValidationErrors({
                                                                                 ...validationErrors,
                                                                                 [parameter]: error,
                                                                             })
                                                                         }}
                                                                         onValidationOk={() => {
                                                                             const {
                                                                                 [parameter]: value, ...rest
                                                                             } = validationErrors;
                                                                             setValidationErrors(rest)
                                                                         }}/>)}

                        </>
                    </EuiFormRow>))}


                </EuiForm>

            </EuiFlexItem>
        </EuiFlexGroup>);
    };


    const Flyout = (<EuiFlyout style={{width: 800}} onClose={closeFlyout} initialFocus="[name=popswitch]">
        <EuiFlyoutHeader>

            <h1>QBF settings</h1>

        </EuiFlyoutHeader>

        <EuiFlyoutBody>
            {form(onChange, selectedQBFSettingId)}
        </EuiFlyoutBody>

        <EuiFlyoutFooter>

            <EuiButtonEmpty onClick={() => {
                closeFlyout()
            }}>Cancel</EuiButtonEmpty>

            <EuiButton isDisabled={Object.keys(validationErrors).length > 0} type="submit"
                       form={FlyoutFormId} fill>
                Save
            </EuiButton>
        </EuiFlyoutFooter>
    </EuiFlyout>);


    const showFlyout = () => setIsFlyoutVisible(true);
    return (<>
        {qbfSettings && (<>
            <EuiButton onClick={showFlyout}>Select a QBF setting</EuiButton>
            <EuiSpacer size="m"/>
            {!cleanSettings && (<>No settings selected.</>)}
            {cleanSettings && (
                <EuiAccordion buttonContent="Current settings">{Object.keys(cleanSettings).map((k, index) => {
                    return (<div key={index}>
                        <div key={index}>
                            <b>{k}</b> {cleanSettings[k]}
                        </div>
                        <EuiSpacer size="s"/>
                    </div>);
                })}
                </EuiAccordion>)}
        </>)}
        <EuiSpacer size="m"/>
        {qbfSettingsLoading && (<div>Loading QBF settings... <br/><EuiLoadingSpinner size="small"/></div>)}
        {qbfSettingsError && (
            <EuiErrorBoundary>Error while loading QBF settings. Please refresh. </EuiErrorBoundary>)}
        {isFlyoutVisible && Flyout}
    </>);
}

export default QBFSettingsFlyOut;
import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageBody,
    EuiCallOut,
    EuiEmptyPrompt,
    EuiButton,
    EuiLoadingLogo,
} from '@elastic/eui';
import {useProcessCalls} from "../../context/processCalls";
import PostProcessesSearchList from "../../components/PostProcessesSearchList";
import {useHistory} from "react-router-dom";

const EmptyPostProcesses = () => {
    return (<EuiEmptyPrompt
        iconType="faceSad"
        title={<h2>It seems empty...</h2>}
        body={<p>Get started with Data Processing by adding a new process!</p>}

    />);
}

const LoadingProcesses = () => {
    return (<EuiEmptyPrompt
        icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
        title={<h2>Loading visualisations and outputs</h2>}
    />)
}

const ErrorLoading = () => {

    const settings = {
        color: 'danger', iconType: 'alert', title: <h2>Unable to load your post processes</h2>, body: (<p>
            There was an error loading the data. Please retry or otherwise contact Jasper for help ;)
        </p>),
    }
    return (<EuiEmptyPrompt {...settings} />)
}

const PostProcessesPage = () => {

        const {getProcessCalls} = useProcessCalls()
        const [postProcesses, setPostProcesses] = useState([])
        const [error, setError] = useState(undefined)
        const [loading, setLoading] = useState(true)

        const history = useHistory();

        const reset = () => {
            setLoading(true)
            setError(undefined)
            setPostProcesses([])
            getProcessCalls().then(processes => {
                setLoading(false)
                setPostProcesses(processes)
            })
                .catch(error => {
                    setLoading(false)
                    setError(error)
                });
        }

        useEffect(() => {
            reset()
        }, [])


        return (<EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType="visualizeApp"
                pageTitle="Data processing jobs"
                description="Process existing data to generate new insights"
                rightSideItems={[
                    <EuiButton onClick={() => history.push("/postprocesses/create")} fill>Run a new process</EuiButton>,
                ]}
            >
                <EuiCallOut title="Data is shared here!" color="warning" iconType="help">
                    <p>
                        While login is per user, data is shared across all accounts for now. Don't be surprised if a
                        new
                        output shows up without you creating it.
                    </p>
                </EuiCallOut>
            </EuiPageHeader>
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    {!loading && error && (<ErrorLoading/>)}
                    {loading && (<LoadingProcesses/>)}
                    {!loading && !error && postProcesses.length === 0 && (<EmptyPostProcesses/>)}
                    {!loading && !error && postProcesses.length > 0 && (
                        <PostProcessesSearchList postProcesses={postProcesses}/>)}
                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>)
    }
;

export default PostProcessesPage;

import React, {useEffect, useState} from 'react';
import {
    EuiButton,
    EuiDatePicker,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiLoadingLogo,
    EuiLoadingSpinner,
    EuiContextMenuItem,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiContextMenuPanel,
    EuiPageHeader,
    EuiPanel,
    useGeneratedHtmlId,
    EuiRange,
    EuiSpacer,
    EuiTitle,
    EuiButtonIcon,
    EuiPopover
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import {useUniqLocations} from "../../context/uniqLocations";
import RawUniqMeasurementTable from "../../components/RawUniqMeasurementTable";
import HistogramElasticChart from "../../components/HistogramElasticChart";
import {useInfrastructures} from "../../context/infrastructures";
import {useRawUniqMeasurements} from "../../context/rawUniqMeasurements";
import WaveformChart from "../../components/WaveformChart";
import DatasetTable from "../../components/DatasetTable";
import Segment from "../../components/Segment";
import QBFSelectionSection from "../../components/QBFSelectionSection";
import UniqLocationUpdateSection from "../../components/UniqLocationUpdateSection";
import DatasetCreationModal from "../../components/DatasetCreationModal";
import RawUniqMeasurementDownloadButton from "../../components/RawUniqMeasurementDownloadButton";
import UniqLocationModal from "../../components/UniqLocationModal";

const UniqLocationByIdPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getUniqLocationById, getHistogramOfUniqLocation, updateLocation} = useUniqLocations()
    const {getInfrastructureByUniqLocation} = useInfrastructures()
    const {getCompleteRawUniqMeasurementById} = useRawUniqMeasurements();


    const [currentMeasurement, setCurrentMeasurement] = useState(undefined)
    const [infrastructure, setInfrastructure] = useState(undefined)
    const [location, setLocation] = useState(undefined)
    const [histogram, setHistogram] = useState(undefined)
    const [filterSettings, setFilterSettings] = useState({
        snr: 0, start_moment: undefined, stop_moment: undefined
    })
    const [localFilterSettings, setLocalFilterSettings] = useState({
        snr: 0, start_moment: undefined, stop_moment: undefined
    })
    const [refreshing, setRefreshing] = useState(false)
    const [error, _] = useState(undefined);

    const resetUsingFilters = () => {
        setRefreshing(true)
        setCurrentMeasurement(undefined)
        getUniqLocationById(id).then(loc => {
            setLocation(loc)

            getInfrastructureByUniqLocation(loc).then(infra => setInfrastructure(infra))

            getHistogramOfUniqLocation(loc, filterSettings).then(histogram => {
                setHistogram(histogram)
                setRefreshing(false)
            });
        })
    }


    useEffect(() => {
        resetUsingFilters()
    }, [filterSettings])


    return (<EuiPageBody panelled>
        {error === undefined && location === undefined && (<EuiPageContent verticalPosition="center"
                                                                           horizontalPosition="center"
                                                                           paddingSize="none"
                                                                           hasShadow={false}
        >
            <EuiEmptyPrompt
                icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                title={<h2>Loading location</h2>}
            />
        </EuiPageContent>)}
        {location !== undefined && (<>
            <EuiPageHeader
                restrictWidth={'100%'}
                iconType={shmnextIcon}
                pageTitle={location.name}
                breadcrumbs={[{text: 'Infrastructures', onClick: () => history.push("/infrastructures")}, {
                    text: infrastructure ? infrastructure.name : '<?>', onClick: () => {
                        if (infrastructure) {
                            history.push(`/infrastructures/${infrastructure.id}`)
                        }
                    }
                }, {
                    text: location.name, onClick: () => history.push(`/uniq_location/${location.id}`)
                }]}
                rightSideItems={[
                    <RawUniqMeasurementDownloadButton location={location} localFilterSettings={localFilterSettings}/>,
                    <UniqLocationModal buttonSize="m" buttonIcon={"documentEdit"} buttonText={"Update"}
                                       text={"Update the location"} initialLocation={location}
                                       onSubmit={(newLocation => {
                                           updateLocation({...location, ...newLocation}).then(() => {
                                               resetUsingFilters()
                                           })
                                       })}/>
                ]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'100%'}>
                    <EuiFlexGroup direction='horizontal' style={{maxWidth: '100%'}}>
                        <EuiFlexItem>
                            <EuiFormRow label="Minimum SNR">
                                <EuiRange
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={localFilterSettings.snr}
                                    showValue
                                    showLabels
                                    onChange={(v) => setLocalFilterSettings({
                                        ...localFilterSettings, 'snr': v.target.value
                                    })}
                                    isDraggable
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow label="Start date">
                                <EuiDatePicker
                                    selected={localFilterSettings.start_moment}
                                    dateFormat="YYYY-MM-DD"
                                    locale="en-UK"
                                    onChange={(e) => setLocalFilterSettings({
                                        ...localFilterSettings, start_moment: e
                                    })}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow label="End date">
                                <EuiDatePicker
                                    dateFormat="YYYY-MM-DD"
                                    locale="en-UK"
                                    selected={localFilterSettings.stop_moment}
                                    onChange={(e) => setLocalFilterSettings({
                                        ...localFilterSettings, stop_moment: e
                                    })}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiFormRow hasEmptyLabelSpace>
                                <>
                                    {!refreshing && (<EuiButton onClick={() => {
                                        setFilterSettings(localFilterSettings)
                                    }}><EuiIcon type='refresh'/> Refresh</EuiButton>)}
                                    {refreshing && (<EuiLoadingSpinner size="m"/>)}
                                </>
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size="l"/>
                    <EuiTitle>
                        <h2>Raw measurements</h2>
                    </EuiTitle>
                    <EuiSpacer size="m"/>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={5}>
                            {currentMeasurement && <>
                                <WaveformChart measurement={currentMeasurement}/>
                            </>}
                        </EuiFlexItem>
                        <EuiFlexItem grow={5}>
                            <RawUniqMeasurementTable
                                onMeasurementSelect={(id) => {
                                    getCompleteRawUniqMeasurementById(id).then(measurement => setCurrentMeasurement(measurement))
                                }}
                                location={location}
                                filters={filterSettings}/>
                        </EuiFlexItem>
                    </EuiFlexGroup>

                    <Segment title="Amount of signals per date">
                        {histogram && (<HistogramElasticChart histogram={histogram}/>)}
                    </Segment>
                    <QBFSelectionSection
                        start_moment={filterSettings.start_moment}
                        stop_moment={filterSettings.stop_moment}
                        snr={filterSettings.snr}
                        uniq_location={location}
                    />
                    <EuiSpacer size="m"/>
                    <Segment title="Datasets">
                        <DatasetTable location={location} onDatasetSelect={(id) => {
                            history.push((`/datasets/${id}`))
                        }}/>
                        <EuiSpacer size="m"/>
                        <DatasetCreationModal uniq_location_id={id}/>
                    </Segment>
                    <UniqLocationUpdateSection location={location}/>

                </EuiPageContentBody>
            </EuiPageContent>

        </>)}
    </EuiPageBody>)
}


export default UniqLocationByIdPage;

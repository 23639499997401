import {VegaLite} from "react-vega";

const WaveformChart = ({measurement}) => {
    const generateChartSpec = (name, i) => {
        return {
            "height": 200,
            "width": 200,
            "data": {
                "name": `table${i}`,
            },
            "transform": [
                {
                    "window": [
                        {
                            "op": "row_number",
                            "as": "index"
                        }
                    ]
                }
            ],
            "mark": {
                "type": "line",
                "color": "black"
            },
            "encoding": {
                "x": {
                    "field": "index",
                    "type": "quantitative",
                    "title": "",
                    "axis": {
                        "labels": true
                    }
                },
                "y": {
                    "field": "data",
                    "type": "quantitative",
                    "title": name,
                    "axis": {
                        "labels": true
                    },
                    "scale": {
                        "domain": [
                            0,
                            65536
                        ]
                    }
                }
            }
        }
    };

    const generateGridSpec = (specs) => ({
        "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
        "columns": 2,
        "width": "container",
        "height": "container",
        "concat": specs.map(({name}, x) => {
            return generateChartSpec(name, x + 1)
        })
    });

    const data1 = measurement.ch1;
    const data2 = measurement.ch2;
    const data3 = measurement.ch3;
    const data4 = measurement.ch4;

    const combinedSpec = generateGridSpec([
        {name: "CH1", data: data1}, {name: "CH2", data: data2}, {name: "CH3", data: data3}, {
            name: "CH4",
            data: data4
        }])

    const combinedData = {'table1': data1, 'table2': data2, 'table3': data3, 'table4': data4}

    return (
        <VegaLite data={combinedData} spec={combinedSpec}/>
    );
}

export default WaveformChart;
import React from "react"
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiSpacer
} from "@elastic/eui"


export default function LoginForm({
                                      requestUserLogin = async ({email, password}) =>
                                          console.log(`Logging in with ${email} and ${password}.`),
                                      handleLoginSuccess = () => {
                                      }
                                  }) {
    const [form, setForm] = React.useState({
        email: "",
        password: ""
    })
    const [errors, setErrors] = React.useState({})

    const handleInputChange = (label, value) => {
        setForm((form) => ({...form, [label]: value}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // if any input hasn't been entered in, return early
        if (!Object.values(form).every((value) => Boolean(value))) {
            setErrors((errors) => ({...errors, form: `You must fill out all fields.`}))
            return
        }
        const result = await requestUserLogin({email: form.email, password: form.password})
        if (!result.success) {
            setErrors((errors) => ({...errors, form: `Incorrect username or password`}))
        } else {
            handleLoginSuccess()
        }
    }

    return (
        <>
            <EuiForm
                component="form"
                onSubmit={handleSubmit}
                isInvalid={Boolean(errors.form)}
                error={[errors.form]}
            >
                <EuiFormRow
                    label="Email"
                    helpText="Enter the email associated with your account."
                    isInvalid={Boolean(errors.email)}
                    error={`Please enter a valid email.`}
                >
                    <EuiFieldText
                        icon="email"
                        placeholder="user@gmail.com"
                        value={form.email}
                        onChange={(e) => handleInputChange("email", e.target.value)}
                        aria-label="Enter the email associated with your account."
                        isInvalid={Boolean(errors.email)}
                    />
                </EuiFormRow>

                <EuiFormRow
                    label="Password"
                    helpText="Enter your password."
                    isInvalid={Boolean(errors.password)}
                    error={`Password must be at least 7 characters.`}
                >
                    <EuiFieldPassword
                        placeholder="••••••••••••"
                        value={form.password}
                        onChange={(e) => handleInputChange("password", e.target.value)}
                        type="dual"
                        aria-label="Enter your password."
                        isInvalid={Boolean(errors.password)}
                    />
                </EuiFormRow>
                <EuiSpacer/>
                <EuiButton type="submit" fill>
                    Submit
                </EuiButton>
            </EuiForm>

            <EuiSpacer size="xl"/>
        </>
    )
}

import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import {AuthenticationProvider} from "./context/authentication";
import {ProcessCallProvider} from "./context/processCalls";
import {QbfProvider} from "./context/qbf";
import {InfrastructureProvider} from "./context/infrastructures";
import {UniqLocationProvider} from "./context/uniqLocations";
import {RawUniqMeasurementProvider} from "./context/rawUniqMeasurements";
import {DatasetProvider} from "./context/datasets";
import {ProcessedUniqMeasurementProvider} from "./context/processedUniqMeasurements";
import {UniqProvider} from "./context/uniqs";

ReactDOM.render(
    <React.StrictMode>
        <AuthenticationProvider>
            <ProcessCallProvider>
                <QbfProvider>
                    <InfrastructureProvider>
                        <UniqLocationProvider>
                            <RawUniqMeasurementProvider>
                                <DatasetProvider>
                                    <ProcessedUniqMeasurementProvider>
                                        <UniqProvider>
                                            <App/>
                                        </UniqProvider>
                                    </ProcessedUniqMeasurementProvider>
                                </DatasetProvider>
                            </RawUniqMeasurementProvider>
                        </UniqLocationProvider>
                    </InfrastructureProvider>
                </QbfProvider>
            </ProcessCallProvider>
        </AuthenticationProvider>
    </React.StrictMode>,
    document.getElementById("root")
)
;
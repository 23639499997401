import {EuiBasicTable, EuiButtonIcon, EuiHealth} from "@elastic/eui";
import {useState} from "react";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";


const UniqTable = ({uniqs, uniqLocations, batteryStatus, onSelect}) => {
    const history = useHistory()
    const uniqsMergedWithExternalInformation = uniqs.map(uniq => {
        const location_for_uniq = uniqLocations[uniq.id]
        const battery_voltage = batteryStatus[uniq.id] ? JSON.parse(batteryStatus[uniq.id]['data'])['battery_voltage'] : undefined

        return {
            'id': uniq.id,
            'uniq_location_id': location_for_uniq ? location_for_uniq['id'] : undefined,
            'uniq_location_name': location_for_uniq ? location_for_uniq['name'] : undefined,
            'start_measurement_moment': location_for_uniq ? location_for_uniq['start_measurement_moment'] : undefined,
            'stop_measurement_moment': location_for_uniq ? location_for_uniq['stop_measurement_moment'] : undefined,
            'battery_voltage': battery_voltage,
            'last_response': uniq['last_contact']
        }
    }).toSorted((a, b) => a['id'] < b['id'] ? -1 : 1)

    const battery_to_color = (battery) => {
        if (!battery) return 'inactive'
        if (battery < 2300) return 'warning'
        if (battery < 2000) return 'danger'
        return 'success'
    }

    const calculatePercentage = (adcValue) => {
        const FULL = 2606
        const EMPTY = 1861
        return ((adcValue - EMPTY) / (FULL - EMPTY)) * 100
    }


    const columns = [{
        field: 'id', name: 'UniQ id', sortable: true, 'data-test-subj': 'nameCell', width: '15%'
    }, {
        field: 'uniq_location_name',
        name: 'Name of UniQ location',
        dataType: 'date',
        'data-test-subj': 'xCell',
        width: '30%',
        render: item => {
            return item ? item : "-"
        }
    }, {
        field: 'start_monitoring_moment',
        name: 'Start of monitoring',
        dataType: 'date',
        'data-test-subj': 'xCell',
        width: '30%',
        render: item => {
            return `${item !== undefined ? moment(item).format("YYYY-MM-DD") : "Not defined"}`
        }
    },
        {
            field: 'stop_monitoring_moment',
            name: 'End of monitoring',
            sortable: false,
            'data-test-subj': 'xCell',
            width: '30%',
            render: item => `${item !== undefined ? moment(item).format("YYYY-MM-DD") : "Not defined"}`
        }, {
            field: 'battery_voltage',
            name: 'Battery',
            sortable: false,
            'data-test-subj': 'xCell',
            width: '25%',
            render: item => {

                return (
                    <EuiHealth
                        color={battery_to_color(item)}>{item ? `${item} (${calculatePercentage(item).toFixed(2)}%)` : 'N/A'}</EuiHealth>)
            }
        },
        {
            field: 'last_response',
            name: 'Previous response',
            sortable: false,
            'data-test-subj': 'xCell',
            width: '25%',
            render: item => `${item !== undefined ? moment(item).format("YYYY-MM-DD HH:mm") : "N/A"}`
        },
        {
            name: 'Link',
            actions: [
                {
                    render: (item) => {
                        if ('uniq_location_id' in item) {
                            return (<EuiButtonIcon iconType='link' onClick={() => {
                                history.push((`/uniq_locations/${item.uniq_location_id}`))
                            }}/>)
                        } else {
                            return (<></>)
                        }
                    },
                    name: 'UniQ location',
                }
            ]
        }

    ];

    const getRowProps = (item) => {
        const {id} = item;
        return {
            'data-test-subj': `row-${id}`, className: 'customRowClass', onClick: () => {
                onSelect(item)
            },
        };
    };

    const getCellProps = (item, column) => {
        const {id} = item;
        const {field} = column;
        return {
            className: 'customCellClass', 'data-test-subj': `cell-${id}-${field}`, textOnly: true,
        };
    };

    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');


    const sorting = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    };

    const onTableChange = ({page, sort}) => {
        if (sort) {
            const {field: sortField, direction: sortDirection} = sort;
            setSortField(sortField);
            setSortDirection(sortDirection);
        }
    };

    return (
        <EuiBasicTable
            items={uniqsMergedWithExternalInformation}
            columns={columns}
            itemId="id"
            rowProps={getRowProps}
            cellProps={getCellProps}

        />
    );
}

export default UniqTable

import React from 'react'
import {
    EuiPanel,
} from "@elastic/eui"

const PlotlyRenderPanel = (props) => {
    return (
        <iframe srcDoc={props.output.content} width="100%" height="500pt"></iframe>
    )


}

export default PlotlyRenderPanel;
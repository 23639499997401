import React from 'react'
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiLink,
    EuiSpacer,
    EuiHorizontalRule,
    EuiTitle,
}
    from
        "@elastic/eui"
import {useProcessCalls} from "../../context/processCalls";


const FileRenderGroup = (props) => {

    const {downloadArtefact} = useProcessCalls();

    const processCall = props.processCall;

    return (
        <div>
            <EuiTitle>
                <h2>
                    Files
                </h2>
            </EuiTitle>
            <EuiSpacer size="m"/>
            <EuiFlexGroup direction="column">
                {processCall.artefacts.map((file, index) => (
                    <>
                        <EuiFlexItem key={index}>
                            <EuiLink onClick={() => downloadArtefact(processCall.id, file)}>{file}</EuiLink>
                        </EuiFlexItem>
                    </>
                ))}
            </EuiFlexGroup>
        </div>

    )


}

export default FileRenderGroup;
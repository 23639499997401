import React from 'react'
import axios from 'axios'
import {API_BASE_URL, SHMNEXT_API_URL} from "../config";
import {useAuthentication} from "./authentication";


const QbfContext = React.createContext({
    getSettings: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    }
})

const QbfProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const getSettings = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/qbf/setting/all`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data['_embedded']['qbf_settings'].filter(s => s.visible !== false))
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }


    return (
        <QbfContext.Provider
            value={{getSettings}} {...props} />
    )

}
const useQBF = () => React.useContext(QbfContext)
export {QbfProvider, useQBF}


import React from 'react'
import {
    EuiFieldText,
} from "@elastic/eui"

const EuiTextAsNumberField = (props) => {
    const {value, error, onValidationError, onValidationOk, onChange} = props
    const patchedOnValidationOk = onValidationOk === undefined ? (a) => {
    } : onValidationOk;
    const patchOnValidationError = onValidationError === undefined ? (a) => {
    } : onValidationError;
    return (
        <EuiFieldText placeholder={0}
                      value={value}
                      isInvalid={error !== undefined}
                      error={error}
                      onChange={(e) => {
                          const parsedResult = parseFloat(e.target.value)
                          if (isNaN(parsedResult)) {
                              patchOnValidationError("This is not a valid number.")
                          } else {
                              patchedOnValidationOk()
                          }
                          onChange((e.target.value), isNaN(parsedResult) ? undefined : parsedResult);
                      }}/>
    )


}

export default EuiTextAsNumberField;
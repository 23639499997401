import React from 'react'
import {
    EuiPanel, EuiSwitch, EuiFieldNumber, EuiFormRow, EuiForm,
} from "@elastic/eui"


const UniqSettingsPanel = ({edited, setEdited, localSettings, setLocalSettings}) => {
    return (
        <EuiPanel hasShadow={false}>
            <EuiForm>
                <EuiFormRow label="Trigger point">
                    <EuiFieldNumber value={localSettings['trigger_point']} onChange={event => {
                        setLocalSettings({...localSettings, 'trigger_point': parseInt(event.target.value)})
                        setEdited(true)
                    }
                    }></EuiFieldNumber>
                </EuiFormRow>
                <EuiFormRow label="SNR point">
                    <EuiFieldNumber value={localSettings['snr_point']} onChange={event => {
                        setLocalSettings({...localSettings, 'snr_point': parseInt(event.target.value)})
                        setEdited(true)
                    }
                    }></EuiFieldNumber>
                </EuiFormRow>
                <EuiFormRow label="Disable SNR check">
                    <EuiSwitch checked={localSettings['disable_snr_check']}
                               onChange={event => {
                                   setEdited(true)
                                   setLocalSettings({
                                       ...localSettings,
                                       'disable_snr_check': event.target.checked
                                   });
                               }}></EuiSwitch>
                </EuiFormRow>
                <EuiFormRow label="Stay awake">
                    <EuiSwitch checked={localSettings['stay_awake']} onChange={event => {
                        setEdited(true)
                        setLocalSettings({
                            ...localSettings,
                            'stay_awake': event.target.checked
                        });
                    }}></EuiSwitch>
                </EuiFormRow>
                <EuiFormRow label="Stay connected">
                    <EuiSwitch checked={localSettings['stay_connected']} onChange={event => {
                        setEdited(true)
                        setLocalSettings({
                            ...localSettings,
                            'stay_connected': event.target.checked
                        });
                    }}></EuiSwitch>
                </EuiFormRow>
                <EuiFormRow label="Save as text">
                    <EuiSwitch checked={localSettings['save_as_txt']} onChange={event => {
                        setEdited(true)
                        setLocalSettings({
                            ...localSettings,
                            'save_as_txt': event.target.checked
                        });
                    }}></EuiSwitch>
                </EuiFormRow>
            </EuiForm>
        </EuiPanel>)
}

export default UniqSettingsPanel;
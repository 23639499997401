import {
    EuiFlexGroup,
    EuiModal,
    EuiForm,
    EuiButton,
    EuiFormRow,
    EuiDatePickerRange,
    EuiModalBody,
    EuiDatePicker,
    EuiModalFooter,
    EuiSpacer,
    EuiButtonEmpty,
    EuiModalHeader,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiSelectable
} from "@elastic/eui";
import React, {useEffect, useState} from "react";
import {useDatasets} from "../../context/datasets";
import {useHistory} from "react-router-dom";
import {useQBF} from "../../context/qbf";


const DatasetCreationModal = ({onChange, uniq_location_id}) => {

    const history = useHistory();
    // Flyout / Setting setup
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentDataset, setCurrentDataset] = useState({})

    const {getSettings} = useQBF();
    const [qbfSettingsLoading, setQBFSettingsLoading] = useState(true);
    const [qbfSettings, setQBFSettings] = useState([]);
    const [qbfSettingsError, setQBFSettingsError] = useState(undefined);


    useEffect(() => {
        setQBFSettingsLoading(true);
        getSettings().then(settings => {
            setQBFSettingsLoading(false);
            setQBFSettings(settings);
        }).catch(() => setQBFSettingsError(true))
    }, [])

    const generateOptionsFromSettings = (settings) => {
        return settings.map(service => {
            return {
                ...service,
                label: service.name,
                checked: settings !== undefined && service.id === currentDataset['qbf_setting_id'] ? 'on' : undefined,
            }
        })
    }

    const {createDataset} = useDatasets()

    const ModalFormId = useGeneratedHtmlId({prefix: 'DatasetCreationModal'});

    const closeModal = () => {
        setCurrentDataset({})
        setIsModalVisible(false);
    }

    const form = () => {
        const startDate = currentDataset['start_moment']
        const endDate = currentDataset['stop_moment']
        return (<EuiFlexGroup>

                <EuiForm id={ModalFormId} component="form"
                         onSubmit={() => {
                             createDataset(uniq_location_id, {
                                 ...currentDataset,
                                 'start_moment': currentDataset['start_moment'] !== undefined ? currentDataset['start_moment'].format('YYYY-MM-DD') : undefined,
                                 'stop_moment': currentDataset['stop_moment'] !== undefined ? currentDataset['stop_moment'].format('YYYY-MM-DD') : undefined,
                             }).then(dataset => history.push(`/datasets/${dataset.id}`));
                             closeModal()
                         }}>
                    <EuiFormRow key='datasetName' helpText='The name of the dataset.'>
                        <EuiFieldText onChange={new_name => setCurrentDataset({
                            ...currentDataset,
                            'name': new_name.target.value
                        })}/>
                    </EuiFormRow>
                    <EuiFormRow key='datasetQBFSetting' helpText='The QBF setting to parse the measurements with.'>
                        <>
                            {qbfSettingsLoading && (<EuiLoadingSpinner></EuiLoadingSpinner>)}
                            {!qbfSettingsLoading && (
                                <EuiSelectable
                                    options={generateOptionsFromSettings(qbfSettings)}
                                    singleSelection={"always"}
                                    allowExclusions={false}
                                    onChange={(newOptions) => {
                                        for (const key in newOptions) {
                                            if (newOptions[key].checked === 'on') {
                                                setCurrentDataset({
                                                    ...currentDataset,
                                                    'qbf_setting_id': newOptions[key].id
                                                });
                                            }
                                        }
                                    }
                                    }
                                >
                                    {(list, search) => (<>
                                        {list}
                                        {search}
                                    </>)
                                    }
                                </EuiSelectable>)}
                            {qbfSettingsError && (<>Error loading QBF settings. Please re-open the modal.</>)}
                        </>
                    </EuiFormRow>
                    <EuiFormRow key='datasetTimeRange'
                                helpText='The range of dates of which raw measurements will be considered. Inclusive at both ends.'>
                        <EuiDatePickerRange
                            isInvalid={startDate > endDate}
                            startDateControl={
                                <EuiDatePicker
                                    selected={startDate}
                                    onChange={(date) => date && setCurrentDataset({
                                        ...currentDataset,
                                        'start_moment': date
                                    })}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="YYYY-MM-DD"
                                    aria-label="Start date"
                                />
                            }
                            endDateControl={
                                <EuiDatePicker
                                    selected={endDate}
                                    onChange={(date) => date && setCurrentDataset({
                                        ...currentDataset,
                                        'stop_moment': date
                                    })}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="YYYY-MM-DD"
                                    aria-label="End date"
                                />
                            }
                        />
                    </EuiFormRow>

                </EuiForm>
            </EuiFlexGroup>
        )
    };


    const Modal = (
        <EuiModal style={{width: 400}} onClose={closeModal} initialFocus="[name=popswitch]">
            <EuiModalHeader>
                <h1>Create a dataset</h1>
            </EuiModalHeader>

            <EuiModalBody>>
                {form()}
            </EuiModalBody>

            <EuiModalFooter>

                <EuiButtonEmpty onClick={() => {
                    closeModal()
                }}>Cancel</EuiButtonEmpty>

                <EuiButton
                    isDisabled={currentDataset['name'] === undefined || currentDataset['qbf_setting_id'] === undefined}
                    type="submit"
                    form={ModalFormId} fill>
                    Create
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    );


    const showModal = () => setIsModalVisible(true);
    return (
        <>
            <EuiButton onClick={showModal}>Create a dataset</EuiButton>
            <EuiSpacer size="m"/>
            {isModalVisible && Modal}
        </>
    );
}

export default DatasetCreationModal;
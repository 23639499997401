import React from 'react'
import {
    EuiAspectRatio,
    EuiImage,
    EuiPanel,
    EuiText,
} from "@elastic/eui"

const ImageRenderPanel = (props) => {
    return (
        <EuiPanel hasShadow={false} hasBorder={false} direction="column">
            <EuiAspectRatio width={1} height={1}>
                <EuiImage
                    size={300}
                    hasShadow
                    allowFullScreen
                    alt={"Output"}
                    src={`data:image/jpeg;base64,${props.output.content}`}
                />
            </EuiAspectRatio>
        </EuiPanel>
    )


}

export default ImageRenderPanel;
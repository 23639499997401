import React, {useEffect, useState} from "react"

import {
    EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel,
    EuiSpacer, EuiText, EuiTextColor, EuiTitle
} from '@elastic/eui';
import {useUniqLocations} from "../../context/uniqLocations";
import UniqLocationTable from "../UniqLocationTable";
import moment from "moment/moment";
import UniqLocationModal from "../UniqLocationModal";
import {useUniqs} from "../../context/uniqs";

const addLocationActiveAttribute = (loc) => {
    const {start_monitoring_moment, stop_monitoring_moment} = loc;
    const start = start_monitoring_moment !== undefined ? moment(start_monitoring_moment) <= moment(new Date()) : false;
    const stop = stop_monitoring_moment !== undefined ? moment(stop_monitoring_moment) >= moment(new Date()) : true;
    loc.active = start && stop;
    return loc
}
const buttonContent = (name, description) => (
    <div>
        <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
            <EuiFlexItem grow={false}>
                <EuiIcon type="logoWebhook" size="m"/>
            </EuiFlexItem>

            <EuiFlexItem>
                <EuiTitle size="xs">
                    <h3>{name}</h3>
                </EuiTitle>
            </EuiFlexItem>
        </EuiFlexGroup>

        <EuiText size="s">
            <p>
                <EuiTextColor color="subdued">
                    {description}
                </EuiTextColor>
            </p>
        </EuiText>
        <EuiSpacer size="s"/>
    </div>
);

const UniqLocationGroupPanel = ({uniqLocationGroup, refresh}) => {
    const [uniqLocations, setUniqLocations] = useState([])
    const {getUniqLocationsByGroup, addUniqLocationToGroup} = useUniqLocations()
    const {getUniqByUniqLocation} = useUniqs();

    const enhanceLocationsWithAssignedUniqs = (uniq_locations) => {
        return new Promise((res, rej) => {
            Promise.all(uniq_locations.map(uniq_location => {
                return new Promise((resolve, reject) => getUniqByUniqLocation(uniq_location).then((v) => resolve({
                    ...uniq_location,
                    'assigned_uniq': v
                })))
            })).then((values) => {
                res(values.reduce((acc, val) => {
                    return [...acc, val]
                }, []))
            });
        });
    }

    useEffect(() => {
        getUniqLocationsByGroup(uniqLocationGroup).then(locations => {
            enhanceLocationsWithAssignedUniqs(locations).then(locations => {
                setUniqLocations(locations.map(addLocationActiveAttribute)
                    .sort((a, b) => a['created_at'] < b['created_at']))
            })
        })
    }, [uniqLocationGroup])


    const amount_active = uniqLocations.reduce((acc, next) => {
        if (next.active) {
            return acc + 1;
        } else {
            return acc
        }
    }, 0)

    return (
        <>
            <EuiAccordion buttonContent={buttonContent(
                uniqLocationGroup.name,
                `Active UniQ locations: ${amount_active}`
            )}>
                <EuiPanel hasBorder={true}>
                    {uniqLocations.length !== 0 &&
                        <UniqLocationTable uniqLocations={uniqLocations}/>}
                    {uniqLocations.length === 0 && (<>No uniq
                        locations for this group.</>)}
                    <EuiSpacer size="m"/>
                    <UniqLocationModal text={"Add a UniQ location"} buttonText={"Create"} initialLocation={{}}
                                       uniqLocationGroup={uniqLocationGroup} refresh={refresh}
                                       onSubmit={(currentUniqLocation) => {
                                           addUniqLocationToGroup(uniqLocationGroup, {
                                               ...currentUniqLocation,
                                               'start_monitoring_moment': currentUniqLocation['start_monitoring_moment'] !== undefined ?
                                                   currentUniqLocation['start_monitoring_moment'].format('YYYY-MM-DD') : undefined,
                                               'stop_monitoring_moment': currentUniqLocation['stop_monitoring_moment'] !== undefined ?
                                                   currentUniqLocation['stop_monitoring_moment'].format('YYYY-MM-DD') : undefined,
                                           }).then(_ => {
                                               refresh()
                                           })
                                       }}/>
                </EuiPanel>


            </EuiAccordion>
            <EuiSpacer size="s"/>
        </>
    )
}


export default UniqLocationGroupPanel
import React, {useState} from "react";
import {EuiIcon, EuiSideNav, slugify} from "@elastic/eui";
import {useHistory} from "react-router-dom";

const SideNav = (props) => {
    const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false);
    const history = useHistory()

    const toggleOpenOnMobile = () => {
        setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };

    const createItem = (name, data = {}) => {
        return {
            id: slugify(name),
            name,
            isSelected: (data.location || "") === props.selected,
            onClick: () => {
                history.push(data.location)
            },
            ...data,
        };
    };


    const sideNav = [
        createItem('Home', {
            location: "/home",
            icon: <EuiIcon type="home"/>,
            items: [],
        }),
        createItem('Data Processing', {
            icon: <EuiIcon type="visualizeApp"/>,
            location: '/postprocesses',
        }),
        createItem('Monitoring', {
            location: '/monitoring',
            icon: <EuiIcon type="lensApp"/>,
            onClick: () => history.push('/monitoring'),
            items: [
                createItem('Infrastructures', {
                    onClick: () => history.push('/infrastructures'),
                    disabled: false,
                }),
                createItem('UniQs', {disabled: false, location: '/uniqs'}),
                createItem('QBF settings', {disabled: true})

            ],
        }),
        createItem('Projects', {
            disabled: true,
            icon: <EuiIcon type="casesApp"/>,
            items: [
                createItem('Overview', {disabled: true, location: "/projects",}),
            ],
        }),
    ];

    return (
        <EuiSideNav
            toggleOpenOnMobile={() => toggleOpenOnMobile()}
            isOpenOnMobile={isSideNavOpenOnMobile}
            style={{width: 192}}
            items={sideNav}
        />
    );
}

export default SideNav
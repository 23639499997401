import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiSelectable,
    EuiFlyoutBody,
    EuiSteps,
    EuiTitle,
    EuiForm,
    EuiFormRow,
    EuiRange,
    EuiSpacer, EuiButton, EuiDatePicker, useGeneratedHtmlId
} from "@elastic/eui";
import React, {useEffect, useState} from "react";
import {useInfrastructures} from "../../context/infrastructures";
import {useUniqLocations} from "../../context/uniqLocations";
import HistogramSelectionChart from "../HistogramSelectionChart";

const InfrastructureSelection = (props) => {

    const options = (props.infrastructures || []).map(infrastructure => {
        return {
            checked: props.selectedInfrastructure && infrastructure.id === props.selectedInfrastructure.id ? "on" : undefined,
            label: infrastructure.name, ...infrastructure
        }
    });

    return (<div>
        <EuiSelectable
            searchable
            allowExclusions={false}
            singleSelection="always"
            options={options}
            onChange={(newOptions) => {
                const selected = newOptions.filter(option => option.checked === "on")[0]
                props.onChange(selected)
            }}
        >
            {(list, search) => (
                <>
                    {search}
                    {list}
                </>
            )}
        </EuiSelectable>
    </div>);
}


const UniqLocationSelection = (props) => {

    const options = (props.uniqLocations || []).map(uniqLocation => {
        return {
            checked: props.selectedUniqLocation !== undefined && uniqLocation.id === props.selectedUniqLocation.id ? "on" : undefined,
            label: uniqLocation.name, ...uniqLocation
        }
    });

    return (<div>
        <EuiSelectable
            searchable
            allowExclusions={false}
            singleSelection="always"
            options={options}
            onChange={(newOptions) => {
                const selected = newOptions.filter(option => option.checked === "on")[0]
                props.onChange(selected)
            }}
        >
            {(list, search) => (
                <>
                    {search}
                    {list}
                </>
            )}
        </EuiSelectable>
    </div>);
}

const RawUniqMeasurementSelectionFlyOut = (props) => {

    const [flyOutOpen, setFlyOutOpen] = useState(false)

    const {getMyInfrastructures} = useInfrastructures()
    const {getUniqLocationsOfInfrastructure, getHistogramOfUniqLocation} = useUniqLocations()


    const [myInfrastructures, setMyInfrastructures] = useState(undefined)
    const [infraStructuresLoading, setInfrastructuresLoading] = useState(true)
    const [uniqLocationsOfInfrastructure, setUniqLocationsOfInfrastructure] = useState(undefined)
    const [uniqLocationsLoading, setUniqLocationsLoading] = useState(true)

    const [selectedInfrastructure, setSelectedInfrastructure] = useState(undefined)
    const [selectedUniqLocation, setSelectedUniqLocation] = useState(undefined)

    const [histogram, setHistogram] = useState(undefined)


    const [snr, setSnr] = useState(0)
    const [startMoment, setStartMoment] = useState(undefined)
    const [stopMoment, setStopMoment] = useState(undefined)

    const FlyoutFormId = useGeneratedHtmlId();
    const {onChange} = props


    useEffect(() => {
        setInfrastructuresLoading(true)
        getMyInfrastructures().then(infrastructures => {
            setMyInfrastructures(infrastructures)
            setInfrastructuresLoading(false)
        }).catch(error => {
            setInfrastructuresLoading(false)
        })
    }, [])

    useEffect(() => {
        setUniqLocationsLoading(true)
        setSelectedUniqLocation(undefined)
        setHistogram(undefined)
        getUniqLocationsOfInfrastructure(selectedInfrastructure).then((uniqLocations) => {
            setUniqLocationsLoading(false)
            setUniqLocationsOfInfrastructure(uniqLocations)
        }).catch(() => {
            setUniqLocationsLoading(false)
            setUniqLocationsOfInfrastructure(undefined)
        })
    }, [selectedInfrastructure])

    useEffect(() => {
        if (selectedUniqLocation === undefined) return;
        getHistogramOfUniqLocation(selectedUniqLocation, {'snr': snr}).then(histogram => {
            setHistogram(histogram)
        })
    }, [selectedUniqLocation])

    const steps = [
            {
                title: 'Select an infrastructure',
                children: <div>
                    <InfrastructureSelection infrastructures={myInfrastructures}
                                             selectedInfrastructure={selectedInfrastructure}
                                             onChange={(i) => setSelectedInfrastructure(i)}/>
                </div>,
                status: selectedInfrastructure !== undefined ? "complete" : "incomplete"
            },
            {
                title: 'Select a uniq location',
                children: <div>
                    {selectedInfrastructure && (
                        <UniqLocationSelection uniqLocations={uniqLocationsOfInfrastructure}
                                               selectedUniqLocation={selectedUniqLocation}
                                               onChange={(u) => setSelectedUniqLocation(u)}/>
                    )}
                    {!selectedInfrastructure && (<div>Please select an infrastructure first</div>)}
                </div>,
                status: selectedUniqLocation !== undefined ? "complete" : "incomplete"
            },
            {
                title: 'Select a waveform range',
                children: (
                    <div>
                        {histogram && <div>

                            <HistogramSelectionChart data={{table: histogram}}/>
                            {/*<EuiSpacer size="l"/>*/}
                            <EuiForm id={FlyoutFormId}>
                                <EuiSpacer size="m"/>
                                <EuiFormRow label="Minimum SNR">
                                    <EuiRange
                                        min={0}
                                        max={100}
                                        step={1}
                                        value={snr}
                                        showValue
                                        showLabels
                                        onChange={(v) => setSnr(v.target.value)}
                                        isDraggable
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="Start date of selected range">
                                    <EuiDatePicker
                                        selected={startMoment}
                                        onChange={(e) => setStartMoment(e)}
                                    />
                                </EuiFormRow>
                                <EuiFormRow label="End date of selected range">
                                    <EuiDatePicker
                                        selected={stopMoment}
                                        onChange={(e) => setStopMoment(e)}
                                    />
                                </EuiFormRow>

                            </EuiForm>
                        </div>}
                    </div>
                ),
                status: selectedUniqLocation !== undefined ? "complete" : "incomplete"
            },
        ]
    ;


    const flyout = (
        <EuiFlyout onClose={() => setFlyOutOpen(false)}>
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Select your waveforms</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <EuiSteps steps={steps}/>
            </EuiFlyoutBody>
        </EuiFlyout>
    );

    const showModal = () => setFlyOutOpen(true);

    return (
        <div>
            <EuiButton onClick={showModal}>Select a waveform range</EuiButton>
            <EuiSpacer size="m"/>

            {snr === undefined && (<div>Minimum SNR: not selected</div>)}
            {snr !== undefined && (<div>Minimum SNR: {snr}</div>)}

            {selectedUniqLocation && (<div>Selected uniq location: {selectedUniqLocation.name}</div>)}
            {!selectedUniqLocation && (<div>Selected uniq location: not selected</div>)}

            {startMoment && (<div>Start date: {startMoment.format('DD-MM-YYYY')} (from 00:00)</div>)}
            {!startMoment && (<div>Start moment: not selected </div>)}

            {stopMoment && (<div>Stop date : {stopMoment.format('DD-MM-YYYY')} (until 23:59)</div>)}
            {!stopMoment && (<div>Stop moment: not selected </div>)}
            {flyOutOpen && flyout}
        </div>
    );
}

export default RawUniqMeasurementSelectionFlyOut;
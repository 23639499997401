import React, {useEffect, useState} from 'react'
import {
    EuiSpacer, EuiFormRow, EuiCallOut, EuiButton, useGeneratedHtmlId, EuiPanel, EuiForm, EuiSwitch, EuiLoadingSpinner
} from "@elastic/eui"


import {useUniqs} from "../../context/uniqs";
import UniqSelection from "../UniqSelection";


const UniqAssignment = ({location}) => {

    const qbfFormId = useGeneratedHtmlId({prefix: 'qbfForm'});

    const findUniqForLocation = (uniqs, location) => {
        return uniqs.reduce((found, uniq) => {
            if (uniq.uniq_location_id === location.id) return uniq.id
            return found
        }, undefined)
    }

    const {assignUniq, getMyUniqs} = useUniqs()

    const [localUniq, setLocalUniq] = useState(undefined)
    const [uniqs, setUniqs] = useState(undefined)
    const [updateSettings, setUpdateSettings] = useState({
        'startThisOne': false,
        'closePrevious': false
    })
    const [updateSuccessful, setUpdateSuccessful] = useState(undefined)

    useEffect(() => {
        getMyUniqs().then(uniqs => {
            setUniqs(uniqs)
            setLocalUniq(findUniqForLocation(uniqs, location))
        });
    }, [])


    return (<>
        {uniqs !== undefined && (<EuiForm id={qbfFormId} component="form" onSubmit={(e) => {
            e.preventDefault()
            assignUniq(localUniq, {...updateSettings, 'uniq_location_id': location.id}).then(() => {
                setUpdateSuccessful(true)
            }).catch(() => {
                setUpdateSuccessful(false)
            })

        }}>
            {updateSuccessful === true && <>
                <EuiCallOut title="Update successful." color="success" iconType="save"/>
                <EuiSpacer size="m"/>
            </>}
            {updateSuccessful === false && <>
                <EuiCallOut title="An error occurred while updating." color="danger" iconType="save"/>
                <EuiSpacer size="m"/>
            </>}
            <EuiFormRow label="UniQ to assign">
                <UniqSelection uniqs={uniqs} selectedUniqId={localUniq} onChange={(selectedUniq) => {
                    setLocalUniq(selectedUniq)
                }}/>
            </EuiFormRow>
            <EuiFormRow label="Do you want to assign today as the start of the current location's monitoring period?">
                <EuiSwitch checked={updateSettings['startThisOne']} onChange={(e) => {
                    setUpdateSettings({...updateSettings, 'startThisOne': e.target.checked})
                }} label="Start current locations' monitoring period?"/>

            </EuiFormRow>
            <EuiFormRow
                label="Do you want to assign today as the end of the UniQs current location's monitoring period?">
                <EuiSwitch checked={updateSettings['closePrevious']} onChange={(e) => {
                    setUpdateSettings({...updateSettings, 'closePrevious': e.target.checked})
                }} label="Close previous locations' monitoring period?"/>

            </EuiFormRow>

            <EuiButton form={qbfFormId} type="submit" fill>
                Submit
            </EuiButton>
        </EuiForm>)}
        {uniqs === undefined && <EuiLoadingSpinner size="m"/>}
    </>)

}

export default UniqAssignment;
import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiEmptyPrompt,
    EuiPageBody,
    EuiPageHeader,
    EuiLoadingLogo,
    EuiPanel,
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import {useInfrastructures} from "../../context/infrastructures";
import {Unity, useUnityContext} from "react-unity-webgl";
import {useAuthentication} from "../../context/authentication";

const SHMXRPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getInfrastructureById} = useInfrastructures()

    const [infra, setInfra] = useState(undefined)
    const [error, setError] = useState(undefined);

    useEffect(() => {

        getInfrastructureById(id).then(infra => {
            setInfra(infra);
        }).catch(e => setError(e));

    }, [])


    const {unityProvider, isLoaded, sendMessage} = useUnityContext({
        loaderUrl: "/build/build_il2cpp_webgl2.loader.js",
        dataUrl: "/build/build_il2cpp_webgl2.data.unityweb",
        frameworkUrl: "/build/build_il2cpp_webgl2.framework.js.unityweb",
        codeUrl: "/build/build_il2cpp_webgl2.wasm.unityweb",
        streamingAssetsUrl: "/build/StreamingAssets/",
        symbolsUrl: "/build/build_il2cpp_webgl2.symbols.json.unityweb",
    });

    useEffect(() => {
            if (isLoaded) {
                sendMessage("Data Controller Installer", "JSSetAuthToken", tokens.access_token);
                sendMessage("Data Controller Installer", "JSSetSelectedInfrastructureUUID", infra.id);
            }
        }
        , [isLoaded])
    const {tokens} = useAuthentication()


    return (<EuiPageBody panelled>
        {error === undefined && infra === undefined && (<EuiPageContent verticalPosition="center"
                                                                        horizontalPosition="center"
                                                                        paddingSize="none"
                                                                        hasShadow={false}
        >
            <EuiEmptyPrompt
                icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                title={<h2>Loading infrastructure</h2>}
            />
        </EuiPageContent>)}
        {infra !== undefined && (<>
            <EuiPageHeader
                iconType={shmnextIcon}
                pageTitle={infra.name}
                breadcrumbs={[{text: 'Overview', onClick: () => history.push("/infrastructures")}, {
                    text: infra.name, onClick: () => history.push(`/infrastructures/${infra.id}`)
                },]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody>
                    <EuiPanel hasShadow={false} hasBorder={false} paddingSize="none">
                        <div className="container">
                            <Unity style={{height: "100%", width: "100%"}} unityProvider={unityProvider}/>
                        </div>
                    </EuiPanel>
                </EuiPageContentBody>
            </EuiPageContent>

        </>)}
    </EuiPageBody>)


}


export default SHMXRPage;

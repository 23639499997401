import {useEffect, useState} from "react";
import {EUI_CHARTS_THEME_DARK} from "@elastic/eui/dist/eui_charts_theme";
import {Axis, BarSeries, Chart, LineSeries, Position, ScaleType, Settings} from "@elastic/charts";
import {useUniqs} from "../../context/uniqs";
import moment from "moment/moment";

const BatteryGraphChart = ({uniq}) => {

    const {getBatteryGraphForUniq} = useUniqs()
    const [batteryGraph, setBatteryGraph] = useState(undefined)
    useEffect(() => {
        getBatteryGraphForUniq(uniq).then(graph => {
            const graph_as_list_of_dicts = Object.keys(graph).reduce((curr, val) => {
                return [...curr, {'date': moment(val).format("YYYY-MM-DD"), 'voltage': graph[val]}]
            }, []).sort((a, b) => {
                return a['date'] < b['date'] ? -1 : 1
            });
            setBatteryGraph(graph_as_list_of_dicts)
        })
    }, [uniq])
    return (
        <>
            {batteryGraph && (
                <Chart size={{height: 200}}>
                    <Settings debug={false} theme={EUI_CHARTS_THEME_DARK.theme}/>
                    <Axis
                        id="bottom"
                        position={Position.Bottom}
                        title="Date"
                        tickFormat={(v) => v}
                    />
                    <Axis id="amount" title="Amount of points" position={Position.Left}
                          tickFormat={d => Number(d).toFixed(2)}/>

                    <LineSeries
                        id="Amount of points"
                        xScaleType={ScaleType.Ordinal}
                        yScaleType={ScaleType.Linear}
                        xAccessor={(item) => item.date}
                        yAccessors={[(item) => item.voltage]}
                        data={batteryGraph}
                    />


                </Chart>)}
        < />


    )
}

export default BatteryGraphChart;
import React from 'react'
import axios from 'axios'
import {useAuthentication} from "./authentication";
import {API_BASE_URL, SHMNEXT_API_URL} from "../config";
import moment from "moment/moment";


const UniqContext = React.createContext({
    updateSettings: (id, settings) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    assignUniq: (id, updateRequest) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getMyUniqs: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getLatestStatusByUniq: (uniq) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getBatteryGraphForUniq: (uniq) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },

    getUniqById: (id) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },

    getUniqByUniqLocation: (loc) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },

})

const UniqProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const updateSettings = (id, update) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniqs/id/${id}`, {
                    ...update,
                    settings: JSON.stringify(update)
                },
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                }).then(response => {
                resolve(response.data)
            })
                .catch(e => {
                    reject(e)
                })
        })
    }

    const assignUniq = (id, updateRequest) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniqs/id/${id}/assign`, updateRequest,
                {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                }).then(response => {
                resolve(response.data)
            })
                .catch(e => {
                    reject(e)
                })
        })
    }

    const getUniqById = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/uniqs/id/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                r.data['settings'] = JSON.parse(r.data['settings'])
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        });
    }

    const getLatestStatusByUniq = (uniq) => {
        return new Promise((resolve, reject) => {
            return axios.get(uniq['_links']['latest_status']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 500 || error.response.status === 403) {
                        logout()
                        reject()
                    } else {
                        resolve(undefined)
                    }

                });
        });
    }


    const getMyUniqs = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/uniqs/all`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data['_embedded']['uniqs'])
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    };

    const getUniqByUniqLocation = (uniq_location) => {
        return new Promise((resolve, reject) => {
            return axios.get(uniq_location['_links']['assigned_uniq']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 500 || error.response.status === 403) {
                        logout()
                        reject()
                    } else {
                        resolve(undefined)
                    }

                });
        });
    }

    const getBatteryGraphForUniq = (uniq_location) => {
        return new Promise((resolve, reject) => {
            return axios.get(uniq_location['_links']['battery_graph']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data.batteryGraph)
            })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 500 || error.response.status === 403) {
                        logout()
                        reject()
                    } else {
                        resolve(undefined)
                    }

                });
        });
    }

    return (
        <UniqContext.Provider
            value={{
                getUniqById,
                getMyUniqs,
                getBatteryGraphForUniq,
                getLatestStatusByUniq,
                updateSettings,
                getUniqByUniqLocation,
                assignUniq
            }} {...props} />
    )

}
const useUniqs = () => React.useContext(UniqContext)
export {UniqProvider, useUniqs}


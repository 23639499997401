import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiEmptyPrompt,
    EuiPageBody,
    EuiText,
    EuiSpacer,
    EuiPanel,
    EuiAccordion,
    EuiPageHeader, EuiLoadingLogo, EuiHorizontalRule,
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import {useProcessCalls} from "../../context/processCalls";
import OutputRenderGroup from "../../components/OutputRenderGroup";
import FileRenderGroup from "../../components/FileRenderGroup";


const PostProcessByIdPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getProcessCallById} = useProcessCalls()

    const [processCall, setProcessCall] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        getProcessCallById(id).then(call => {
            setProcessCall(call)
        })
            .catch(e => {
                setError(e)
            })
    }, [])


    const errorSettings = (error) => ({
        color: 'danger', iconType: 'alert', title: <h2>Error while processing</h2>, body: (<p>
            Error: {error}
        </p>),
    })

    const capitalize = (str) => {
        return str.split(" ").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")
    }

    const sortObjectsFirst = (a, b) => {
        const type_a = typeof a;
        const type_b = typeof b;
        if (type_a === type_b) return 0;
        if (type_a === 'object') return -1;
        if (type_b === 'object') return 1;
        return 0;
    }

    const renderParameters = (parameters) => {
        return (
            <div>
                {Object.keys(parameters).sort(sortObjectsFirst).map(k => {
                    if (k === 'input_file') return (<></>); // For now, do not render input files.
                    if (typeof (parameters[k]) === 'object') {
                        return (
                            <EuiAccordion buttonContent={capitalize(k.replace("_", " "))}>
                                <div>{k}: {Object.keys(parameters[k]).map(p_k => {
                                    return (
                                        <div>
                                            <b>{p_k}</b>: {parameters[k][p_k]}
                                        </div>
                                    )
                                })}
                                </div>
                            </EuiAccordion>)
                    }
                    return (<div><b>{capitalize(k.replace("_", " "))}</b>: {parameters[k]}</div>);
                })}
            </div>
        )
    }

    return (
        <EuiPageBody panelled>
            {error === undefined && processCall === undefined && (
                <EuiPageContent verticalPosition="center"
                                horizontalPosition="center"
                                paddingSize="none"
                                hasShadow={false}
                >
                    <EuiEmptyPrompt
                        icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                        title={<h2>Loading visualisation</h2>}
                    />
                </EuiPageContent>
            )}
            {processCall !== undefined && (
                <>
                    <EuiPageHeader
                        restrictWidth={'75%'}
                        iconType={shmnextIcon}
                        pageTitle={processCall.name}
                        description={processCall.description}
                        breadcrumbs={[{text: 'Overview', onClick: () => history.push("/postprocesses")}, {
                            text: processCall.name,
                            onClick: () => history.push(`/postprocesses/${processCall.id}`)
                        },]}
                    />
                    <EuiPageContent
                        paddingSize="none"
                        color="transparent"
                        borderRadius="none"
                    >
                        {processCall.processing === false && processCall.error === true && (
                            <EuiPageContentBody restrictWidth={'75%'}>
                                <EuiEmptyPrompt {...errorSettings(processCall.error_message)} />
                            </EuiPageContentBody>
                        )}
                        {processCall.processing !== true && (
                            <EuiPageContentBody restrictWidth={'75%'}>
                                <EuiPanel hasShadow={false} hasBorder={false}>
                                    <EuiText><h2>Parameters</h2></EuiText>
                                    {renderParameters(processCall.serviceCall.parameters)}
                                </EuiPanel>
                                <EuiHorizontalRule/>
                                <EuiSpacer size="l"/>
                                <OutputRenderGroup processCall={processCall}/>
                                {processCall.artefacts && processCall.artefacts.length !== 0 && (
                                    <div><EuiSpacer size={"m"}/>
                                        <FileRenderGroup processCall={processCall}/></div>)}
                            </EuiPageContentBody>
                        )}
                        {processCall.processing === true && (
                            <EuiPageContentBody restrictWidth={'75%'}>
                                <EuiEmptyPrompt
                                    iconType="faceHappy"
                                    title={<h2>Still processing</h2>}
                                    body={<p>Last received status
                                        update: {processCall.pct ? `${processCall.pct}%` : "not received"}</p>}

                                />
                            </EuiPageContentBody>
                        )}
                    </EuiPageContent>

                </>
            )}
        </EuiPageBody>)


}


export default PostProcessByIdPage;

import React from 'react'
import {
    EuiFieldNumber, EuiButton, EuiSpacer, EuiButtonIcon
} from "@elastic/eui"

const NumberListInput = (props) => {
    const {value, error, onValidationError, onValidationOk, onChange} = props
    return (<div>
        {value.map((v, i) => (<div>
                    <span><EuiFieldNumber
                        value={v}
                        onChange={(e) => {
                            let new_value = [...value];
                            new_value[i] = e.target.value;
                            onChange(new_value, new_value)
                        }}
                        append={[
                            <EuiButtonIcon iconType="minusInCircle" onClick={() => {
                                let new_value = [...value]
                                new_value.splice(i, 1)
                                onChange(new_value, new_value);
                            }}>-</EuiButtonIcon>]}

                    /></span>
            <EuiSpacer size="m"/>
        </div>))}
        <EuiButton onClick={() => {
            onChange([...value, 0], [...value, 0])
        }}>Add</EuiButton>
    </div>)


}

export default NumberListInput;
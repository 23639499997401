import React, {useEffect, useState} from 'react';
import {
    EuiEmptyPrompt, EuiHorizontalRule,
    EuiLoadingLogo,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import Segment from "../../components/Segment";
import UniqSchedulingPanel from "../../components/UniqSchedulingPanel";
import {useUniqs} from "../../context/uniqs";
import UniqSettingsPanel from "../../components/UniqSettingsPanel";

const UniqByIdPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getUniqById} = useUniqs();

    const [uniq, setUniq] = useState(undefined);
    const [error, setError] = useState(undefined)


    const reset = () => {
        getUniqById(id).then((uniq) => setUniq(uniq));
    }

    useEffect(() => {
        reset();
    }, [])


    return (<EuiPageBody panelled>
        {error === undefined && uniq === undefined && (<EuiPageContent verticalPosition="center"
                                                                       horizontalPosition="center"
                                                                       paddingSize="none"
                                                                       hasShadow={false}
        >
            <EuiEmptyPrompt
                icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                title={<h2>Loading UniQ</h2>}
            />
        </EuiPageContent>)}
        {uniq !== undefined && (<>
            <EuiPageHeader
                restrictWidth={'100%'}
                iconType={shmnextIcon}
                pageTitle={`UniQ ${uniq.id} - Configuration and history`}
                breadcrumbs={[{
                    text: `UniQ ${uniq.id}`, onClick: () => history.push(`/uniq/${uniq.id}`)
                }]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'100%'}>
                    <Segment title="Settings">{uniq && (
                        <UniqSettingsPanel uniq={uniq}/>)}
                    </Segment>
                    <EuiHorizontalRule/>
                    <Segment title="Scheduling">{uniq && (
                        <UniqSchedulingPanel uniq={uniq}/>)}
                    </Segment>

                </EuiPageContentBody>
            </EuiPageContent>

        </>)}
    </EuiPageBody>)
}


export default UniqByIdPage;

import React, {useState} from 'react'
import axios from 'axios'
import {SHMNEXT_API_URL} from "../config";


const AuthContext = React.createContext({
    tokens: {},
    login: () => null,
    logout: () => null,
    isAuthenticated: () => null
})

const AuthenticationProvider = (props) => {

    const existingTokens = JSON.parse(localStorage.getItem("tokens") || "{}");

    const [tokens, setAccessTokens] = useState(existingTokens)

    const isAuthenticated = () => {
        return tokens.access_token !== undefined;
    }

    const setTokens = (tokens) => {
        localStorage.setItem("tokens", JSON.stringify(tokens));
        setAccessTokens(tokens)
    }

    const login = ({email, password}) => {
        return axios.post(`${SHMNEXT_API_URL}/auth/login`, {
            username: email.toLocaleLowerCase(), password
        }).then((response) => {
            if (response.status === 200) {
                setTokens(response.data);
                return {success: true}
            }
            return {success: false, message: "Invalid username or password"}
        }).catch(_ => {
            return {success: false, message: "Invalid username or password"}
        })
    }

    const logout = () => {
        setTokens({access_token: undefined, email: undefined})
    }

    return (
        <AuthContext.Provider value={{tokens, isAuthenticated, login, logout}} {...props} />
    )
}
const useAuthentication = () => React.useContext(AuthContext)
export {AuthenticationProvider, useAuthentication}


import {
    Axis, BarSeries, Chart, Position, ScaleType, Settings
} from '@elastic/charts';
import {EUI_CHARTS_THEME_DARK} from "@elastic/eui/dist/eui_charts_theme";
import moment from "moment";

const HistogramElasticChart = ({histogram}) => {

    const histogramSorted = [...histogram].filter(a => a.date > '2000-01-01').sort((a, b) => {
        return a.date < b.date ? -1 : 1
    });
    const dictionary = histogramSorted.reduce((obj, item) => ({...obj, [item.date]: item}), {});
    var getDaysArray = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    let histogramFilled;
    if (histogramSorted.length === 0) {
        histogramFilled = []
    } else {
        histogramFilled = getDaysArray(histogramSorted[0].date, histogramSorted[histogramSorted.length - 1].date)
            .reduce((curr, val) => {
                const dateString = moment(val).format("YYYY-MM-DD");
                return [...curr, {
                    date: dateString,
                    amount_of_points: dictionary[dateString] !== undefined ? dictionary[dateString].amount_of_points : 0
                }];
            }, [])
    }


    return (
        <Chart size={{height: 200}}>
            <Settings debug={false} theme={EUI_CHARTS_THEME_DARK.theme}/>
            <Axis
                id="bottom"
                position={Position.Bottom}
                title="Date"
                tickFormat={(v) => v}
            />
            <Axis id="amount" title="Amount of points" position={Position.Left} tickFormat={d => Number(d).toFixed(2)}/>
            <BarSeries
                id="Amount of points"
                xScaleType={ScaleType.Ordinal}
                yScaleType={ScaleType.Linear}
                xAccessor={(item) => item.date}
                yAccessors={[(item) => item.amount_of_points]}
                data={histogramFilled.length > 0 ? histogramFilled : [{date: '1970-01-1', amount_of_points: 0}]}
            />

        </Chart>

    );
}
export default HistogramElasticChart
import React from 'react'
import axios from 'axios'
import {SHMNEXT_API_URL} from "../config";
import {useAuthentication} from "./authentication";


const InfrastructuresContext = React.createContext({
    getMyInfrastructures: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getMyFavoritedInfrastructures: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getInfrastructureByUniqLocation: () => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
    getInfrastructureById: (id) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
    isFavorite: (id) => {
        return new Promise((resolve, _) => {
            resolve(false)
        })
    },
    favorite: (id) => {
        return new Promise((resolve, _) => {
            resolve()
        })
    },
    unfavorite: (id) => {
        return new Promise((resolve, _) => {
            resolve()
        })
    },
    addInfrastructure: (i) => {
        return new Promise((resolve, _) => {
            resolve()
        })
    },
    updateInfrastructure: (i) => {
        return new Promise((resolve, _) => {
            resolve()
        })
    },
})

const InfrastructureProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const getMyInfrastructures = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/infrastructures/my`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data['_embedded']['infrastructures'])
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getInfrastructureById = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/infrastructures/id/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getInfrastructureByUniqLocation = (loc) => {
        return new Promise((resolve, reject) => {
            return axios.get(loc['_links']['infrastructure']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }


    const isFavorite = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/infrastructures/my/favorite/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data.favorite)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const favorite = (id) => {
        return new Promise((resolve, reject) => {
            return axios.put(`${SHMNEXT_API_URL}/infrastructures/my/favorite/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve()
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const addInfrastructure = (infrastructure) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/infrastructures/`, infrastructure, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const updateInfrastructure = (id, infrastructure) => {
        return new Promise((resolve, reject) => {
            return axios.put(`${SHMNEXT_API_URL}/infrastructures/id/${id}`, infrastructure, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const unfavorite = (id) => {
        return new Promise((resolve, reject) => {
            return axios.delete(`${SHMNEXT_API_URL}/infrastructures/my/favorite/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve()
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getMyFavoritedInfrastructures = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/infrastructures/my/favorite`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data['_embedded']['infrastructures'])
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }


    return (
        <InfrastructuresContext.Provider
            value={{
                getMyInfrastructures,
                getMyFavoritedInfrastructures,
                getInfrastructureById,
                getInfrastructureByUniqLocation,
                isFavorite,
                favorite,
                unfavorite,
                addInfrastructure,
                updateInfrastructure
            }} {...props} />
    )

}
const useInfrastructures = () => React.useContext(InfrastructuresContext)
export {InfrastructureProvider, useInfrastructures}


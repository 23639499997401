import React, {useState} from "react"

import {
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiAccordion,
    EuiLoadingSpinner,
    EuiSpacer,
    EuiForm,
    EuiFormRow,
    EuiFieldNumber,
    useGeneratedHtmlId,
    EuiCallOut
} from '@elastic/eui';
import QBFSettingsFlyOut from "../QBFSettingsFlyOut";
import {useProcessCalls} from "../../context/processCalls";
import {useHistory} from "react-router-dom";
import moment from "moment";

const QBFSelectionSection = ({start_moment, stop_moment, snr, uniq_location}) => {

    const {createProcessCall} = useProcessCalls();

    const history = useHistory()

    const [name, setName] = useState('');
    const [hit_rate, setHitRate] = useState(5);
    const [window, setWindow] = useState(5);
    const [settings, setSettings] = useState(undefined)
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(false)

    const simpleAccordionId = useGeneratedHtmlId({prefix: 'qbfSection'});
    const qbfFormId = useGeneratedHtmlId({prefix: 'qbForm'});

    const submit = () => {
        setSending(true)
        createProcessCall({
            name: name,
            serviceName: 'report',
            parameters: {
                'waveform_range': {
                    'start_moment': start_moment !== undefined ? start_moment.format("YYYY-MM-DD") : undefined,
                    'stop_moment': stop_moment !== undefined ? stop_moment.format("YYYY-MM-DD") : undefined,
                    uniq_location_id: uniq_location.id,
                    snr
                },
                'qbf_setting': settings,
                'hit_rate': hit_rate,
                'window': window,
            }
        }).then((createdProcessCallResponse => {
            history.push(`/postprocesses/${createdProcessCallResponse.id}`)
        })).catch((e) => {
            setSending(false)
            setError(true)
        });
    }

    const generateNameFromLocation = (location, start_moment, stop_moment, snr) => {
        let result = `${location.name}`
        if (start_moment !== undefined && stop_moment === undefined) {
            result += ` | ${moment(start_moment).format("YYYY-MM-DD")} - now`
        }
        if (start_moment === undefined && stop_moment !== undefined) {
            result += ` | until ${moment(stop_moment).format("YYYY-MM-DD")}`
        }
        if (start_moment === undefined && stop_moment === undefined) {
            result += ` | all points`
        }
        if (start_moment !== undefined && stop_moment !== undefined) {

            result += ` | ${moment(start_moment).format("YYYY-MM-DD")} - ${moment(stop_moment).format("YYYY-MM-DD")}`
        }
        if (snr !== 0) {
            result += ` | more than ${snr} decibel SNR`
        }
        result += ` | ${moment(new Date()).format("DD/MM/YYYY")}`

        return result
    }

    const modal = (
        <EuiAccordion id={simpleAccordionId} buttonContent="Run QBF on these measurements">
            {error && (<EuiCallOut title="Sorry, there was an error" color="danger" iconType="alert">Please
                alert
                the service administrator and try again later.</EuiCallOut>)}
            <EuiForm id={qbfFormId}>
                <EuiFormRow label="Name" helpText="The name of the QBF run. Please make it descriptive.">
                    <EuiFlexGroup fullWidth>
                        <EuiFlexItem grow={true}>
                            <EuiFieldText value={name} onChange={(e) => {
                                setName(e.target.value)
                            }}/>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <div>
                                <EuiButton onClick={() => {
                                    setName(generateNameFromLocation(uniq_location, start_moment, stop_moment, snr))
                                }} iconType="documentEdit">
                                    Generate name
                                </EuiButton>
                            </div>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiFormRow label="Settings">
                    <QBFSettingsFlyOut onChange={(e) => {
                        setSettings(e)
                    }}/>
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiFormRow label="Hit rate [hits / hour] (for clustering)">
                    <EuiFieldNumber value={hit_rate} onChange={(e) => {
                        setHitRate(e.target.value)
                    }}/>
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiFormRow label="Window [days] (for clustering)">
                    <EuiFieldNumber value={window} onChange={(e) => {
                        setWindow(e.target.value)
                    }}/>
                </EuiFormRow>
            </EuiForm>

            {!sending && (
                <>
                    <EuiSpacer size="m"/>
                    <EuiButton isDisabled={!name || !settings} type="submit" form={qbfFormId} onClick={submit} fill>
                        Run
                    </EuiButton>
                </>)}
            {sending && (<EuiLoadingSpinner size="m"/>)}
        </EuiAccordion>
    )

    return (
        <div>
            {modal}
        </div>
    )
}

export default QBFSelectionSection
import React, {useEffect, useState} from 'react'
import {
    EuiSpacer, EuiFormRow, EuiCallOut, EuiButton, EuiForm, useGeneratedHtmlId
} from "@elastic/eui"

import EuiTextAsNumberField from "../EuiTextAsNumberField";
import {useUniqLocations} from "../../context/uniqLocations";
import NumberListInput from "../NumberListInput";

const GeometryEditor = ({location}) => {

    const qbfFormId = useGeneratedHtmlId({prefix: 'datasetForm'});

    const {updateGeometry} = useUniqLocations()

    const [settings, setSettings] = useState(location)
    const [display, setDisplay] = useState(location)

    const [updateSuccessful, setUpdateSuccessful] = useState(undefined)


    const onChange = (d, p) => {
        setDisplay(d);
        setSettings(p)
    }


    const parseList = (l) => l.map(v => parseFloat(v))
    return (<EuiForm id={qbfFormId} component="form" onSubmit={(e) => {
        e.preventDefault()

        updateGeometry(location, {
            ...settings,
            id: undefined,
            position: undefined,
            coordinates_x_stiffeners: parseList(settings['coordinates_x_stiffeners']),
            coordinates_y_stiffeners: parseList(settings['coordinates_y_stiffeners'])
        })
            .then(() => {
                setUpdateSuccessful(true)
            })
            .catch((e) => {
                setUpdateSuccessful(false)
            })
    }}>
        {updateSuccessful === true && <>
            <EuiCallOut title="Update successful." color="success" iconType="save"/>
            <EuiSpacer size="m"/>
        </>}
        {updateSuccessful === false && <>
            <EuiCallOut title="An error occurred while updating." color="danger" iconType="save"/>
            <EuiSpacer size="m"/>
        </>}
        <EuiFormRow label="X - left" helpText="Horizontal distance from left side to uniq center">
            <EuiTextAsNumberField value={display.grid_length_left} n="grid_length_left" onChange={(d, p) => {
                onChange({...display, grid_length_left: d}, {...settings, grid_length_left: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="X - right" helpText="Horizontal distance from right side to uniq center">
            <EuiTextAsNumberField value={display.grid_length_right} n="grid_length_right" onChange={(d, p) => {
                onChange({...display, grid_length_right: d}, {...settings, grid_length_right: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="Y - top" helpText="Vertical distance from top side to uniq center">
            <EuiTextAsNumberField value={display.grid_length_top} n="grid_length_top" onChange={(d, p) => {
                onChange({...display, grid_length_top: d}, {...settings, grid_length_top: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="Y - bottom" helpText="Horizontal distance from bottom side to uniq center">
            <EuiTextAsNumberField value={display.grid_length_bottom} n="grid_length_bottom" onChange={(d, p) => {
                onChange({...display, grid_length_bottom: d}, {...settings, grid_length_bottom: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="Grid size" helpText="">
            <EuiTextAsNumberField value={display.grid_size} n="grid_size" onChange={(d, p) => {
                onChange({...display, grid_size: d}, {...settings, grid_size: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="The thickness of the plate in cm" helpText="">
            <EuiTextAsNumberField value={display.plate_thickness} n="t" onChange={(d, p) => {
                onChange({...display, plate_thickness: d}, {...settings, plate_thickness: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="The thickness of the stiffeners in cm" helpText="">
            <EuiTextAsNumberField value={display.stiffener_thickness} n="stiffener_thickness" onChange={(d, p) => {
                onChange({...display, stiffener_thickness: d}, {...settings, stiffener_thickness: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="Length of the UniQ in cm" helpText="">
            <EuiTextAsNumberField value={display.length_uniq} n="length_uniq" onChange={(dp, p) => {
                onChange({...display, length_uniq: dp}, {...settings, length_uniq: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="m"/>
        <EuiFormRow label="Width of the UniQ in cm" helpText="">
            <EuiTextAsNumberField value={display.width_uniq} n="width_uniq" onChange={(d, p) => {
                onChange({...display, width_uniq: d}, {...settings, width_uniq: p})
            }}/>
        </EuiFormRow>
        <EuiSpacer size="l"/>
        <EuiFormRow label="X coordinates of the stiffeners"
                    helpText="The value is the middle coordinate, the width of the stiffeners will be used in the QBF itself.">
            <NumberListInput value={settings.coordinates_x_stiffeners} n="coordinates_x_stiffeners"
                             onChange={(d, p) => {
                                 onChange({...display, coordinates_x_stiffeners: d}, {
                                     ...settings,
                                     coordinates_x_stiffeners: p
                                 })
                             }}/>
        </EuiFormRow>
        <EuiSpacer size="l"/>
        <EuiFormRow label="Y coordinates of the stiffeners"
                    helpText="The value is the middle coordinate, the width of the stiffeners will be used in the QBF itself.">
            <NumberListInput value={settings.coordinates_y_stiffeners} n=" coordinates_y_stiffeners"
                             onChange={(d, p) => {
                                 onChange({...display, coordinates_y_stiffeners: d}, {
                                     ...settings,
                                     coordinates_y_stiffeners: p
                                 })
                             }}/>
        </EuiFormRow>
        <EuiSpacer size=" l"/>
        <EuiButton form={qbfFormId} type=" submit" fill>
            Submit
        </EuiButton>
    </EuiForm>)

}


// These variable names are not my fault.


export default GeometryEditor;
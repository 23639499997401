import React from 'react'
import axios from 'axios'
import {API_BASE_URL} from "../config";
import {useAuthentication} from "./authentication";


const ProcessCallContext = React.createContext({
    getProcessCalls: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getProcessCallById: (id) => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    },
    getServices: () => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    },
    downloadArtefact: () => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    },
    uploadInput: () => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    },
    createProcessCall: (values) => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    }
})

const ProcessCallProvider = (props) => {

    const {tokens: {access_token, refresh_token}, logout} = useAuthentication()

    const getServices = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${API_BASE_URL}/services`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data.services)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getProcessCalls = () => {
        return new Promise((resolve, reject) => {
            return axios.get(`${API_BASE_URL}/processCalls/`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(response => {
                resolve(response.data.data);
            }).catch(_ => {
                logout()
                reject()
            })
        })

    }
    const getProcessCallById = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${API_BASE_URL}/processCalls/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(response => {
                resolve(response.data.data)
            }).catch(_ => {
                logout()
                reject()
            })
        })
    }
    const createProcessCall = (serviceCall) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${API_BASE_URL}/services`, {
                ...serviceCall,
                access_token,
                refresh_token
            }, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(response => {
                resolve(response.data)
            })
                .catch(e => {
                    reject(e)
                })
        })
    }

    const downloadArtefact = (id, name) => {
        const url = `${API_BASE_URL}/processCalls/artefacts/${id}/${name}`

        return axios.get(url, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(reason => {
            console.log(reason)
            logout();
        })

    }

    const uploadInput = (id, file) => {
        const url = `${API_BASE_URL}/processCalls/inputs/${id}/${file.name}`

        // TODO: add upload

    }


    return (
        <ProcessCallContext.Provider
            value={{
                getProcessCalls,
                getProcessCallById,
                getServices,
                createProcessCall,
                downloadArtefact,
                uploadInput
            }} {...props} />
    )

}
const useProcessCalls = () => React.useContext(ProcessCallContext)
export {ProcessCallProvider, useProcessCalls}


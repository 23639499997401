import React from 'react'
import {
    EuiPanel,
    EuiText,
} from "@elastic/eui"
import {Vega, VegaLite} from "react-vega";
import ReactMarkdown from "react-markdown";

const VegaLiteRenderPanel = (props) => {

    const vega = props.output.content;

    return (
        <EuiPanel hasShadow={false} hasBorder={false} direction="column">
            <EuiText>
                <ReactMarkdown>{vega}</ReactMarkdown>
            </EuiText>
        </EuiPanel>
    )


}

export default VegaLiteRenderPanel;
import React from 'react'
import axios from 'axios'
import {SHMNEXT_API_URL} from "../config";
import {useAuthentication} from "./authentication";
import moment from "moment";


const ProcessedUniqMeasurementContext = React.createContext({
    getProcessedMeasurementById: () => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
    getProcessedUniqMeasurementsByDataset: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    download: (filter) => {

    }
})

const ProcessedUniqMeasurementProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const getProcessedUniqMeasurementsByDataset = (dataset, filters) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${dataset["_links"]["processed_measurements"]["href"]}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                },
                params: {
                    'snr': filters.snr,
                    'start_moment': moment(filters.start_moment || null).isValid() ? moment(filters.start_moment).format("YYYY-MM-DD") : undefined,
                    'stop_moment': moment(filters.stop_moment || null).isValid() ? moment(filters.stop_moment).format("YYYY-MM-DD") : undefined
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }
    const getProcessedMeasurementById = (id) => {
        return new Promise((resolve, reject) => {
                const url = `${SHMNEXT_API_URL}/processed_uniq_measurements/id/${id}`
                return axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    },
                })
                    .then(r => {
                        resolve(r.data)
                    })
                    .catch((error) => {
                        logout()
                        reject()
                    });
            }
        )
    }

    return (
        <ProcessedUniqMeasurementContext.Provider
            value={{
                getProcessedUniqMeasurementsByDataset,
                getProcessedMeasurementById,
            }} {...props} />
    )

}
const useProcessedUniqMeasurements = () => React.useContext(ProcessedUniqMeasurementContext)
export {ProcessedUniqMeasurementProvider, useProcessedUniqMeasurements,}


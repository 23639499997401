import {Redirect, Switch, withRouter, Route} from "react-router-dom";
import HomePage from "../../pages/home";
import SideNav from "../SideNav";
import PostProcessesPage from "../../pages/postprocesses";
import React from "react";
import {EuiPage, EuiPageSideBar} from "@elastic/eui";
import PostProcessByIdPage from "../../pages/postprocess_by_id";
import CreatePostProcessPage from "../../pages/create_postprocess_page";

import TemporaryDownloadPage from "../../pages/temporary_download_page";

import MonitoringPage from "../../pages/monitoring";
import InfrastructuresPage from "../../pages/infrastructures";
import InfrastructureByIdPage from "../../pages/infrastructure_by_id";
import UniqLocationByIdPage from "../../pages/uniq_location_by_id";
import DatasetByIdPage from "../../pages/dataset_by_id";
import UniqByIdPage from "../../pages/uniq_by_id";
import UniqsPage from "../../pages/uniqs";
import SHMXRPage from "../../pages/shmviz";


const Layout = (props) => {

    return (<EuiPage paddingSize="none">
        <EuiPageSideBar paddingSize="l" sticky>
            <SideNav selected={props.location.pathname}/>
        </EuiPageSideBar>
        <Switch>
            <Route path="/home" exact component={HomePage}/>
            <Route path="/download" exact component={TemporaryDownloadPage}/>
            <Route path="/monitoring" exact component={MonitoringPage}/>
            <Route path="/infrastructures" exact component={InfrastructuresPage}/>
            <Route path="/shmviz/:id" exact component={SHMXRPage}/>
            <Route path="/infrastructures/:id" exact component={InfrastructureByIdPage}/>
            <Route path="/uniq_locations/:id" exact component={UniqLocationByIdPage}/>
            <Route path="/postprocesses" exact component={PostProcessesPage}/>
            <Route path="/postprocesses/create" exact component={CreatePostProcessPage}/>
            <Route path="/postprocesses/:id" exact component={PostProcessByIdPage}/>
            <Route path="/datasets/:id" exact component={DatasetByIdPage}/>
            <Route path="/uniqs" exact component={UniqsPage}/>
            <Route path="/uniqs/:id" exact component={UniqByIdPage}/>
            <Route path="/" render={() => <Redirect to="/home"/>}/>
        </Switch>
    </EuiPage>);

}

export default withRouter(Layout);

import React from 'react'
import axios from 'axios'
import {API_BASE_URL, SHMNEXT_API_URL} from "../config";
import {useAuthentication} from "./authentication";


const DatasetContext = React.createContext({
    getDatasetsByUniqLocation: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    togglePrimary: (id) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getDatasetById: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getFirstDateOfDataset: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    createDataset: (uniq_location_id, parameters) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
})

const DatasetProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()


    const getFirstDateOfDataset = (dataset) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${dataset['_links']['processed_measurements']['href']}?page=0&size=1`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                console.log(r)
                if (!r.data._embedded) {
                    resolve(undefined);
                } else {
                    resolve(r.data['_embedded']['processed_uniq_measurements'][0]['measured_at'])
                }
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getDatasetsByUniqLocation = (location) => {
        return new Promise((resolve, reject) => {
            return axios.get(location['_links']['datasets']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                if (r.status === 200) {
                    if ('_embedded' in r.data) {
                        resolve(r.data['_embedded']['datasets'])
                    } else {
                        resolve([])
                    }
                } else if (r.status === 404) {
                    resolve([])
                }

            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getDatasetById = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/datasets/id/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const createDataset = (uniq_location_id, parameters) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/datasets/uniq_location/${uniq_location_id}`, parameters, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const setPrimary = (dataset_id) => {
        return new Promise((resolve, reject) => {
            return axios.put(`${SHMNEXT_API_URL}/datasets/id/${dataset_id}/primary`, {},
                {
                    headers: {
                        'Authorization':
                            `Bearer ${access_token}`
                    }
                }
            ).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    return (
        <DatasetContext.Provider
            value={{
                getDatasetsByUniqLocation,
                getDatasetById,
                setPrimary,
                getFirstDateOfDataset,
                createDataset
            }} {...props} />
    )

}
const useDatasets = () => React.useContext(DatasetContext)
export {DatasetProvider, useDatasets,}


import React, {useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageBody,
    EuiIcon,
    EuiButton,
    EuiSpacer,
    EuiPageHeader,
} from '@elastic/eui';

import {useRawUniqMeasurements} from "../../context/rawUniqMeasurements";
import RawUniqMeasurementSelectionFlyOut from "../../components/RawUniqMeasurementSelectionFlyOut";

const TemporaryDownloadPage = (props) => {
    const [downloadState, setDownloadState] = useState(undefined)


    const {download} = useRawUniqMeasurements();

    const attemptDownload = (n) => {
        download(downloadState)
    }

    return (
        <EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                pageTitle="Download"
                breadcrumbs={[
                    {
                        text: (
                            <>
                                <EuiIcon size="s" type="arrowLeft"/> Return
                            </>
                        ),
                        color: 'primary',
                        'aria-current': false,
                        href: '#',
                        onClick: (e) => e.preventDefault(),
                    }]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    <RawUniqMeasurementSelectionFlyOut
                        current={downloadState}
                        onChange={(e) => setDownloadState(e)}
                    /> <EuiSpacer size="l"/>
                    <EuiButton isDisabled={downloadState === undefined} onClick={() => {
                        attemptDownload(downloadState)
                    }}>Download</EuiButton>
                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>)


}


export default TemporaryDownloadPage;

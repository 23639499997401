import React from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageBody,
    EuiSpacer,
    EuiTitle,
    EuiIcon,
    EuiText,
    EuiCallOut,
    EuiFlexGrid,
    EuiFlexItem,
    EuiCard
} from '@elastic/eui';
import shmnextIcon from './shmnext.svg';
import ChangeLog from "../../components/Changelog";
import {useHistory} from "react-router-dom";


const HomePage = () => {
    const history = useHistory();
    return (
        <EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType={shmnextIcon}
                pageTitle="COMPASS"
                description="COMputerized Postprocessing and Analysis Software System"
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    <EuiText grow={false} size="m">
                        <EuiCallOut
                            title="Welcome to the beta version of the new COMPASS"
                            iconType="search"
                        >
                            <p>
                                Remember to check back regularly for
                                sweet updates :).
                            </p>
                        </EuiCallOut>
                    </EuiText>
                    <EuiSpacer/>
                    <EuiTitle>
                        <h2>Features</h2>
                    </EuiTitle>
                    <EuiSpacer size="m"/>
                    <EuiFlexGrid columns={3}>
                        <EuiFlexItem>

                            <EuiCard
                                icon={<EuiIcon size="xxl" type="visualizeApp"/>}
                                title="Data Processing"
                                description="Run on-demand Data Processing and visualise the results."
                                onClick={() => {
                                    history.push("/postprocesses")
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>

                            <EuiCard
                                icon={<EuiIcon size="xxl" type="monitoringApp"/>}
                                title="Monitoring"
                                description="Monitor the current status of the sensors, incoming data, and proceeding processing."
                                betaBadgeProps={{
                                    label: 'BETA',
                                    toolTipContent: 'This module is not complete yet. Wait for more features!',
                                }}
                                onClick={() => {
                                    history.push('/monitoring')
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem>

                            <EuiCard
                                icon={<EuiIcon size="xxl" type="filebeatApp"/>}
                                title="Projects"
                                description="See reports and other valuable documents per project."
                                onClick={() => {
                                }}
                                isDisabled
                            />
                        </EuiFlexItem>

                    </EuiFlexGrid>
                    <EuiSpacer/>
                    <ChangeLog/>

                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>
    );
};

export default HomePage;

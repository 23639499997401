import React, {useEffect, useState} from 'react';
import {EuiBasicTable, EuiText, EuiSpacer, EuiHorizontalRule, formatDate, EuiButtonIcon} from "@elastic/eui"
import {useDatasets} from "../../context/datasets";


const DatasetTable = ({location, onDatasetSelect}) => {
    const {getDatasetsByUniqLocation, setPrimary} = useDatasets()
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
        pageSizeOptions: [10, 100],
        showPerPageOptions: true
    })
    const [currentPage, setCurrentPage] = useState([])
    const [total, setTotal] = useState(undefined)

    const reset = () => {
        getDatasetsByUniqLocation(location).then(page => {
            setCurrentPage(page)
            setTotal(page.length)
        })
    }

    useEffect(() => {
        reset()

    }, [pagination, location])

    const columns = [
        {
            field: 'id',
            name: 'Identifier',
        },
        {
            field: 'name',
            "name": "Name"
        },
        {
            field: 'start_moment',
            name: 'From',
            render: (item) => item ? formatDate(item) : 'Not specified'
        },
        {
            field: 'stop_moment',
            name: "To",
            render: (item) => item ? formatDate(item) : 'Not specified'
        },
        {
            name: "Actions",
            actions: [{
                render: (item) => (
                    <EuiButtonIcon onClick={() => {
                        setPrimary(item.id).then(() => {
                            reset()
                        })

                    }}
                                   iconType={item.primary ? "starFilled" : "starEmpty"}> {item.primary ? "Yes" : "No"}</EuiButtonIcon>
                )
            }, {
                name: 'Link to entity',
                render: (item) => (
                    <EuiButtonIcon iconType='link' onClick={() => onDatasetSelect(item.id)}>yeet</EuiButtonIcon>
                )
            }]
        }
    ]

    const resultsCount =
        (
            <>
                <strong>
                    {pagination.pageSize * pagination.pageIndex + 1}-{pagination.pageSize * pagination.pageIndex + pagination.pageSize}
                </strong>{' '}
                of {total}
            </>
        );

    return (
        <div>
            <EuiText size="xs">
                Showing {resultsCount} <strong>Datasets</strong>
            </EuiText>
            <EuiSpacer size="s"/>
            <EuiHorizontalRule margin="none" style={{height: 2}}/>
            <EuiBasicTable
                tableCaption="Demo for EuiBasicTable with pagination"
                items={currentPage}
                columns={columns}
                pagination={{...pagination, totalItemCount: total}}
                onChange={({page}) => {
                    setPagination({...pagination, pageIndex: page.index, pageSize: page.size})
                }}
            />
        </div>
    )
}

export default DatasetTable;
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useAuthentication} from "../../context/authentication";
import {
    EuiPageHeader, EuiPageHeaderSection, EuiTitle, EuiPageContent, EuiPageContentBody, EuiPageBody, EuiPage
} from '@elastic/eui';
import LoginForm from "../../components/LoginForm";

function Login(props) {
    const {isAuthenticated, login} = useAuthentication();
    const history = useHistory()
    useEffect(() => {
        if (isAuthenticated()) {
            history.push('/')
        }
    }, [])

    return (
        <EuiPage>
            <EuiPageBody component="section">
                <EuiPageHeader>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>COMPASS - Login</h1>
                        </EuiTitle>
                    </EuiPageHeaderSection>
                </EuiPageHeader>
                <EuiPageContent verticalPosition="center" horizontalPosition="center">
                    <EuiPageContentBody>
                        <LoginForm requestUserLogin={login} handleLoginSuccess={() => {
                            history.push('/')
                        }}/>
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </EuiPage>);
}

export default Login;

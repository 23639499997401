import React from 'react'
import {
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiSpacer,
}
    from
        "@elastic/eui"

import VegaLiteRenderPanel from '../VegaLiteRenderPanel'
import MarkdownRenderPanel from "../MarkdownRenderPanel";
import ImageRenderPanel from "../ImageRenderPanel";
import PlotlyRenderPanel from "../PlotlyRenderPanel";

const OutputRenderGroup = (props) => {

    const processCall = props.processCall;

    return (
        <EuiFlexGroup direction="column">
            {processCall.outputs.map((output, index) => {
                switch (output.type) {
                    case "text":
                    case "markdown":
                        return (<div key={index}><MarkdownRenderPanel output={output}/> <EuiHorizontalRule/>
                            <EuiSpacer size="m"/></div>);
                    case "vega-lite":
                        return (<div key={index}><VegaLiteRenderPanel output={output}/> <EuiHorizontalRule/>
                            <EuiSpacer size="m"/></div>);
                    case "plotly":
                        return (<div key={index}><PlotlyRenderPanel output={output}/> <EuiHorizontalRule/>
                            <EuiSpacer size="m"/></div>);
                    case "image":
                        return (<div key={index}><ImageRenderPanel output={output}/> <EuiHorizontalRule/>
                            <EuiSpacer size="m"/></div>);
                    default:
                        return (<div key={index}></div>);
                }
            })}


        </EuiFlexGroup>
    )


}

export default OutputRenderGroup;
import React, {useEffect, useState} from 'react';
import {
    EuiButton,
    EuiDatePicker,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiLoadingLogo,
    EuiLoadingSpinner,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiRange,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import {useUniqLocations} from "../../context/uniqLocations";
import {useInfrastructures} from "../../context/infrastructures";
import {useDatasets} from "../../context/datasets";
import {useProcessedUniqMeasurements} from "../../context/processedUniqMeasurements";
import moment from "moment";

const DatasetByIdPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getInfrastructureByUniqLocation} = useInfrastructures()
    const {getUniqLocationByRelatedObject} = useUniqLocations()
    const {getDatasetById, getFirstDateOfDataset} = useDatasets()
    const {getProcessedUniqMeasurementsByDataset} = useProcessedUniqMeasurements()


    const [infrastructure, setInfrastructure] = useState(undefined)
    const [location, setLocation] = useState(undefined)
    const [dataset, setDataset] = useState(undefined)
    const [filterSettings, setFilterSettings] = useState({
        snr: 0, start_moment: undefined, end_moment: undefined
    })
    const [localFilterSettings, setLocalFilterSettings] = useState({
        snr: 0, start_moment: undefined, end_moment: undefined
    })
    const [currentMeasurements, setCurrentMeasurements] = useState(undefined)
    const [refreshing, setRefreshing] = useState(false)
    const [error, setError] = useState(undefined);

    const resetUsingFilters = () => {
        setRefreshing(true)
        setCurrentMeasurements(undefined)

        getProcessedUniqMeasurementsByDataset(dataset, filterSettings).then(measurements => {
            setCurrentMeasurements(measurements)
            setRefreshing(false)
        })
    }

    useEffect(() => {
        getDatasetById(id).then(dataset => {
            setDataset(dataset)
            getUniqLocationByRelatedObject(dataset).then(loc => {
                setLocation(loc)
                getInfrastructureByUniqLocation(loc).then(infra => setInfrastructure(infra))

                getFirstDateOfDataset(dataset).then(datetime => {

                    if (datetime !== undefined) {
                        setFilterSettings({
                            ...filterSettings,
                            start_moment: moment(datetime),
                            end_moment: moment(datetime).add('days', 7)
                        })
                        setLocalFilterSettings({
                            ...localFilterSettings,
                            start_moment: moment(datetime),
                            end_moment: moment(datetime).add('days', 7)
                        })
                    }

                });
            })
        });

    }, [])

    useEffect(() => {
        if (dataset !== undefined) {
            resetUsingFilters()
        }
    }, [filterSettings])


    return (<EuiPageBody panelled>
        {error === undefined && dataset === undefined && (<EuiPageContent verticalPosition="center"
                                                                          horizontalPosition="center"
                                                                          paddingSize="none"
                                                                          hasShadow={false}
        >
            <EuiEmptyPrompt
                icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                title={<h2>Loading location</h2>}
            />
        </EuiPageContent>)}

        {dataset !== undefined && (<>
            <EuiPageHeader
                restrictWidth={'100%'}
                iconType={shmnextIcon}
                pageTitle={dataset.name}
                breadcrumbs={[
                    {text: 'Infrastructures', onClick: () => history.push("/infrastructures")},
                    {
                        text: infrastructure ? infrastructure.name : '<?>', onClick: () => {
                            if (infrastructure) {
                                history.push(`/infrastructures/${infrastructure.id}`)
                            }
                        }
                    },
                    {
                        text: location ? location.name : '<?>', onClick: () => {
                            if (location) {
                                history.push(`/uniq_locations/${location.id}`)
                            }
                        }
                    },
                    {
                        text: dataset.name, onClick: () => history.push(`/datasets/${dataset.id}`)
                    }]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                {dataset.finished_processing === true && (<EuiPageContent verticalPosition="center"
                                                                          horizontalPosition="center"
                                                                          paddingSize="none"
                                                                          hasShadow={false}
                >
                    <EuiEmptyPrompt
                        color="warning"
                        iconType="alert"
                        title={<h2>This dataset is still being processed.</h2>}
                    />
                </EuiPageContent>)}

                {dataset.finished_processing === false && (
                    <EuiPageContentBody restrictWidth={'100%'}>
                        <EuiFlexGroup direction='horizontal' style={{maxWidth: '100%'}}>
                            <EuiFlexItem>
                                <EuiFormRow label="Minimum SNR">
                                    <EuiRange
                                        min={0}
                                        max={100}
                                        step={1}
                                        value={localFilterSettings.snr}
                                        showValue
                                        showLabels
                                        onChange={(v) => setLocalFilterSettings({
                                            ...localFilterSettings, 'snr': v.target.value
                                        })}
                                        isDraggable
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow label="Start date">
                                    <EuiDatePicker
                                        dateFormat="YYYY-MM-DD"
                                        locale="en-UK"
                                        selected={localFilterSettings.start_moment}
                                        onChange={(e) => setLocalFilterSettings({
                                            ...localFilterSettings, start_moment: e
                                        })}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow label="End date">
                                    <EuiDatePicker
                                        dateFormat="YYYY-MM-DD"
                                        locale="en-UK"
                                        selected={localFilterSettings.end_moment}
                                        onChange={(e) => setLocalFilterSettings({
                                            ...localFilterSettings, end_moment: e
                                        })}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow hasEmptyLabelSpace>
                                    <EuiButton onClick={() => {
                                        setLocalFilterSettings({
                                            ...localFilterSettings,
                                            start_moment: filterSettings.start_moment.subtract('days', 7),
                                            end_moment: filterSettings.end_moment.subtract('days', 7)
                                        })

                                    }}><EuiIcon type='framePrevious'/> Previous week</EuiButton>
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow hasEmptyLabelSpace>
                                    <EuiButton onClick={() => {
                                        setLocalFilterSettings({
                                            ...localFilterSettings,
                                            start_moment: filterSettings.start_moment.add('days', 7),
                                            end_moment: filterSettings.end_moment.add('days', 7)
                                        })
                                    }}>Next week <EuiIcon type='frameNext'/></EuiButton>
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow hasEmptyLabelSpace>
                                    <>
                                        {!refreshing && (<EuiButton onClick={() => {
                                            setFilterSettings(localFilterSettings)
                                        }}><EuiIcon type='refresh'/> Refresh</EuiButton>)}
                                        {refreshing && (<EuiLoadingSpinner size="m"/>)}
                                    </>
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size="l"/>
                        <EuiTitle>
                            <h2>Processed measurements</h2>
                        </EuiTitle>
                        <EuiSpacer size="m"/>
                        <EuiFlexGroup>

                        </EuiFlexGroup>
                    </EuiPageContentBody>)}
            </EuiPageContent>

        </>)}
    </EuiPageBody>)
}


export default DatasetByIdPage;

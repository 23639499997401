import {
    EuiModal,
    EuiForm,
    EuiButton,
    EuiFormRow,
    EuiDatePickerRange,
    EuiModalBody,
    EuiDatePicker,
    EuiModalFooter,
    EuiSpacer,
    EuiButtonEmpty,
    EuiModalHeader,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiAccordion, EuiFlexItem, EuiTitle
} from "@elastic/eui";
import React, {useState} from "react";
import {useUniqLocations} from "../../context/uniqLocations";
import EuiTextAsNumberField from "../EuiTextAsNumberField";


const UniqLocationGroupCreationModal = ({infrastructure, refresh}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCommitting, setIsCommitting] = useState(false);
    const [currentUniqLocationGroup, setCurrentUniqLocationGroup] = useState({
        'name': "",
        'relative_position': {
            'x': 0,
            'y': 0,
            'z': 0
        },
        'relative_rotation': {
            'q1': 0,
            'q2': 0,
            'q3': 0,
            'q4': 0
        },
        'material_crit_crack_length_mm': 100,
        'material_crit_growth_rate_um_per_day': 500,
        'material_hits_per_um': 1
    })
    const [currentDisplayUniqLocationGroup, setCurrentDisplayUniqLocationGroup] = useState({
        'name': "",
        'relative_position': {
            'x': 0,
            'y': 0,
            'z': 0
        },
        'relative_rotation': {
            'q1': 0,
            'q2': 0,
            'q3': 0,
            'q4': 0
        },
        'material_crit_crack_length_mm': 100,
        'material_crit_growth_rate_um_per_day': 500,
        'material_hits_per_um': 1
    })

    const {addUniqLocationGroupToInfrastructure} = useUniqLocations();

    const ModalFormId = useGeneratedHtmlId({prefix: 'UniqLocationGroupCreationModal'});

    const closeModal = () => {
        setCurrentUniqLocationGroup({})
        setIsModalVisible(false);
    }

    function onChange(display, parsed) {
        setCurrentDisplayUniqLocationGroup(display)
        setCurrentUniqLocationGroup(parsed)
    }

    const form = () => {
        const startDate = currentDisplayUniqLocationGroup['start_monitoring_moment']
        const endDate = currentDisplayUniqLocationGroup['stop_monitoring_moment']
        return (
            <EuiForm id={ModalFormId} component="form"
                     onSubmit={() => {
                         setIsCommitting(true)
                         addUniqLocationGroupToInfrastructure(infrastructure, {
                             ...currentUniqLocationGroup,
                             'start_monitoring_moment': currentUniqLocationGroup['start_monitoring_moment'] !== undefined ?
                                 currentUniqLocationGroup['start_monitoring_moment'].format('YYYY-MM-DD') : undefined,
                             'stop_monitoring_moment': currentUniqLocationGroup['stop_monitoring_moment'] !== undefined ?
                                 currentUniqLocationGroup['stop_monitoring_moment'].format('YYYY-MM-DD') : undefined,
                         }).then(_ => {
                             refresh()
                         })
                         closeModal()
                     }}>
                <EuiFormRow key='datasetName' label="Name" helpText='The name of the UniQ location.'>
                    <EuiFieldText value={currentDisplayUniqLocationGroup['name']}
                                  onChange={e => {
                                      onChange(
                                          {...currentDisplayUniqLocationGroup, 'name': e.target.value},
                                          {...currentUniqLocationGroup, 'name': e.target.value}
                                      )
                                  }}/>
                </EuiFormRow>
                <EuiFormRow key='datasetTimeRange'
                            label="Monitoring time range"
                            helpText='The planned range of dates that this group will be monitored. Inclusive at both ends and will propagate as default to locations in the group.'>
                    <EuiDatePickerRange
                        isInvalid={startDate > endDate}
                        startDateControl={
                            <EuiDatePicker
                                selected={startDate}
                                onChange={(date) => date && onChange({
                                    ...currentDisplayUniqLocationGroup,
                                    'start_monitoring_moment': date
                                }, {
                                    ...currentUniqLocationGroup,
                                    'start_monitoring_moment': date
                                })}
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="YYYY-MM-DD"
                                aria-label="Start date"
                            />
                        }
                        endDateControl={
                            <EuiDatePicker
                                selected={endDate}
                                onChange={(date) => date && onChange({
                                    ...currentDisplayUniqLocationGroup,
                                    'stop_monitoring_moment': date
                                }, {
                                    ...currentUniqLocationGroup,
                                    'stop_monitoring_moment': date
                                })}
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="YYYY-MM-DD"
                                aria-label="End date"
                            />
                        }
                    />
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Location</h3>
                            </EuiTitle>
                        </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="X" helpText="Distance on x-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_position']['x']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'x': display}
                                                      },
                                                      {...currentUniqLocationGroup, 'relative_rotation': {'x': parsed}}
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Y" helpText="Distance on y-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_position']['y']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'y': display}
                                                      }, {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'y': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Z" helpText="Distance on z-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_position']['z']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'z': display}
                                                      }, {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'z': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Rotation</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    }
                    paddingSize="l"
                >
                    <EuiFormRow label="Q1" helpText="Quaternion - value 1">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_rotation']['q1']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'q1': display}
                                                      },
                                                      {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'q1': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q2" helpText="Quaternion - value 2">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_rotation']['q2']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'q2': display}
                                                      },
                                                      {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'q2': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q3" helpText="Quaternion - value 3">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_rotation']['q3']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'q3': display}
                                                      },
                                                      {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'q3': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q4" helpText="Quaternion - value 4">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['relative_rotation']['q4']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'relative_rotation': {'q4': display}
                                                      },
                                                      {
                                                          ...currentUniqLocationGroup,
                                                          'relative_rotation': {'q4': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Crack growth parameters</h3>
                            </EuiTitle>
                        </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="material_crit_crack_length_mm" helpText="material_crit_crack_length_mm">
                        <EuiTextAsNumberField
                            value={currentDisplayUniqLocationGroup['material_crit_crack_length_mm']}
                            onChange={(display, parsed) => {
                                onChange(
                                    {
                                        ...currentDisplayUniqLocationGroup,
                                        'material_crit_crack_length_mm': display
                                    },
                                    {...currentUniqLocationGroup, 'material_crit_crack_length_mm': parsed}
                                );
                            }}/>
                    </EuiFormRow>
                    <EuiFormRow label="material_crit_growth_rate_um_per_day"
                                helpText="material_crit_growth_rate_um_per_day">
                        <EuiTextAsNumberField
                            value={currentDisplayUniqLocationGroup['material_crit_growth_rate_um_per_day']}
                            onChange={(display, parsed) => {
                                onChange(
                                    {
                                        ...currentDisplayUniqLocationGroup,
                                        'material_crit_growth_rate_um_per_day': display
                                    },
                                    {
                                        ...currentUniqLocationGroup,
                                        'material_crit_growth_rate_um_per_day': parsed
                                    }
                                );
                            }}/>
                    </EuiFormRow>
                    <EuiFormRow label="material_hits_per_um" helpText="material_hits_per_um">
                        <EuiTextAsNumberField value={currentDisplayUniqLocationGroup['material_hits_per_um']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayUniqLocationGroup,
                                                          'material_hits_per_um': display
                                                      },
                                                      {
                                                          ...currentUniqLocationGroup,
                                                          'material_hits_per_um': parsed
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
            </EuiForm>
        )
    };


    const Modal = (
        <EuiModal style={{width: 400}} onClose={closeModal} initialFocus="[name=popswitch]">
            <EuiModalHeader>
                <h1>Create a UniQ location</h1>
            </EuiModalHeader>

            <EuiModalBody>
                {form()}
            </EuiModalBody>

            <EuiModalFooter>

                <EuiButtonEmpty onClick={() => {
                    closeModal()
                }}>Cancel</EuiButtonEmpty>

                {!isCommitting && (<EuiButton
                    isDisabled={currentUniqLocationGroup['name'] === undefined}
                    type="submit"
                    form={ModalFormId} fill>
                    Create
                </EuiButton>)}
                {isCommitting && (<EuiLoadingSpinner s='m'/>)}

            </EuiModalFooter>
        </EuiModal>
    );


    const showModal = () => setIsModalVisible(true);
    return (
        <>
            <EuiButton size="s" display="fill" iconType="plus" onClick={showModal}>Add a UniQ location group</EuiButton>
            {isModalVisible && Modal}
        </>
    );
}

export default UniqLocationGroupCreationModal;
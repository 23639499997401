import React from "react";

import '@elastic/eui/dist/eui_theme_dark.css';
import '@elastic/charts/dist/theme_dark.css';

import {EuiProvider} from '@elastic/eui';
import {useAuthentication} from "./context/authentication";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Login from "./pages/login";
import Layout from "./components/Layout";

export function App() {
    const {isAuthenticated} = useAuthentication();

    const PrivateRoute = ({component, ...rest}) => {
        return (<Route
            {...rest}
            render={props => isAuthenticated() ? (React.createElement(component, props)) : (<Redirect
                to={{
                    pathname: "/login", state: {
                        from: props.location,
                    },
                }}
            />)}
        />);
    }

    return (
        <EuiProvider colorMode="dark">
            <Router>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <PrivateRoute exact={false} path="/" component={Layout}/>
                </Switch>

            </Router>
        </EuiProvider>);
}

import React from "react"
import {EuiSpacer, EuiTitle} from "@elastic/eui";


const Segment = ({title, children}) => (
    <>
        <EuiTitle>
            <h2>{title}</h2>
        </EuiTitle>
        <EuiSpacer size="m"/>
        {children}
        <EuiSpacer size="l"/>
    </>
);

export default Segment
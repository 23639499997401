import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiEmptyPrompt,
    EuiPageBody,
    EuiButton,
    EuiPageHeader,
    EuiLoadingLogo,
    EuiText, EuiSpacer, EuiImage, EuiHorizontalRule

} from '@elastic/eui';

import {useHistory} from "react-router-dom";
import shmnextIcon from "../home/shmnext.svg";
import {useUniqLocations} from "../../context/uniqLocations";
import {useInfrastructures} from "../../context/infrastructures";
import UniqLocationGroupPanel from "../../components/UniqLocationGroupPanel";
import UniqLocationGroupCreationModal from "../../components/UniqLocationGroupCreationModal";
import InfrastructureModal from "../../components/InfrastructureModal";

const InfrastructureByIdPage = (props) => {
    const history = useHistory();
    const id = props.match.params.id

    const {getInfrastructureById, updateInfrastructure, isFavorite, unfavorite, favorite} = useInfrastructures()
    const {getUniqLocationGroupByInfrastructure} = useUniqLocations()

    const [infra, setInfra] = useState(undefined)
    const [externalFavorite, setExternalFavorite] = useState(undefined)
    const [externalFavoriteChanging, setExternalFavoriteChanging] = useState(false)

    const [uniqLocationGroups, setUniqLocationGroups] = useState(undefined)
    const [error, setError] = useState(undefined);

    const refresh = () => {

        getInfrastructureById(id).then(infra => {
            setInfra(infra);
            getUniqLocationGroupByInfrastructure(infra).then(
                groups => setUniqLocationGroups(
                    groups.sort((a, b) => a['created_at'] < b['created_at'])
                )
            )
        }).catch(e => setError(e));

        setExternalFavorite(true)
        isFavorite(id).then(fav => {
            setExternalFavorite(fav)
            setExternalFavoriteChanging(false)
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    const toggleFavorite = () => {
        setExternalFavoriteChanging(true)
        if (externalFavorite) {
            unfavorite(id).then(_ => {
                setExternalFavoriteChanging(false)
                setExternalFavorite(false)
            })
        } else {
            favorite(id).then(_ => {
                setExternalFavoriteChanging(false)
                setExternalFavorite(true)
            })
        }
    }

    return (<EuiPageBody panelled>
        {error === undefined && infra === undefined && (<EuiPageContent verticalPosition="center"
                                                                        horizontalPosition="center"
                                                                        paddingSize="none"
                                                                        hasShadow={false}
        >
            <EuiEmptyPrompt
                icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
                title={<h2>Loading infrastructure</h2>}
            />
        </EuiPageContent>)}
        {infra !== undefined && (<>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType={shmnextIcon}
                pageTitle={infra.name}
                breadcrumbs={[{text: 'Overview', onClick: () => history.push("/infrastructures")}, {
                    text: infra.name, onClick: () => history.push(`/infrastructures/${infra.id}`)
                },]}
                rightSideItems={[<InfrastructureModal iconType="documentEdit" mode="Update" text={"Update"}
                                                      initial={infra}
                                                      onSubmit={(infra) => {
                                                          updateInfrastructure(id, infra).then(_ => {
                                                              refresh()
                                                          })
                                                      }}/>,
                    <EuiButton
                        fill={externalFavorite}
                        iconType='heart'
                        onClick={() => {
                            if (!externalFavoriteChanging) {
                                toggleFavorite()
                            }
                        }}
                    >{externalFavorite ? 'Unfavorite' : 'Favorite'}</EuiButton>]}
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    <EuiImage
                        size={250}
                        hasShadow
                        allowFullScreen
                        alt={infra.name}
                        src={infra.b64_image}

                    />
                    <EuiSpacer size="m"/>

                    <EuiText>
                        <h2>UniQ locations by group</h2>
                    </EuiText>
                    <EuiSpacer size="s"/>


                    {uniqLocationGroups && uniqLocationGroups.map((group, index) => (
                        <UniqLocationGroupPanel key={index} uniqLocationGroup={group} refresh={refresh}/>))}
                    <EuiSpacer size="m"/>
                    <UniqLocationGroupCreationModal infrastructure={infra} refresh={refresh}/>
                    <EuiHorizontalRule/>


                </EuiPageContentBody>
            </EuiPageContent>

        </>)}
    </EuiPageBody>)


}


export default InfrastructureByIdPage;

import {
    EuiButton,
    EuiContextMenuItem,
    EuiContextMenuPanel,
    EuiLoadingSpinner,
    EuiPopover,
    useGeneratedHtmlId
} from "@elastic/eui";
import React, {useState} from "react";
import {useRawUniqMeasurements} from "../../context/rawUniqMeasurements";

const RawUniqMeasurementDownloadButton = ({location, localFilterSettings}) => {

    const [downloading, setDownloading] = useState(false)


    const [isDownloadPopoverOpen, setDownloadPopoverOpen] = useState(false);
    const splitButtonPopoverId = useGeneratedHtmlId({
        prefix: 'splitButtonPopover',
    });

    const {download} = useRawUniqMeasurements()

    const onDownloadButtonClick = () => {
        setDownloadPopoverOpen(!isDownloadPopoverOpen);
    };

    const closeDownloadPopover = () => {
        setDownloadPopoverOpen(false);
    };

    return (
        <>
            {!downloading && (
                <EuiPopover
                    id={splitButtonPopoverId}
                    button={
                        <EuiButton
                            display="base"
                            size="m"
                            iconType="boxesVertical"
                            aria-label="Download"
                            onClick={onDownloadButtonClick}
                        >Download measurements in current view</EuiButton>
                    }
                    isOpen={isDownloadPopoverOpen}
                    closePopover={closeDownloadPopover}
                    panelPaddingSize="none"
                    anchorPosition="downLeft"
                >
                    <EuiContextMenuPanel size="s" items={[
                        <EuiContextMenuItem key="json" onClick={() => {
                            setDownloading(true)
                            download({...localFilterSettings, 'uniq_location_id': location.id}, "json").then(() => {
                                setDownloading(false)
                            })
                            closeDownloadPopover()
                        }
                        }>
                            JSON
                        </EuiContextMenuItem>,
                        <EuiContextMenuItem key="txt" onClick={() => {
                            download({...localFilterSettings, 'uniq_location_id': location.id}, "zip").then(() => {
                                setDownloading(false)
                            })
                            closeDownloadPopover()
                        }
                        }>
                            Text
                        </EuiContextMenuItem>,
                    ]}/>
                </EuiPopover>
            )}
            {downloading && <EuiLoadingSpinner size="m"/>}
        </>
    )
}

export default RawUniqMeasurementDownloadButton
import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageBody,
    EuiImage,
    EuiText,
    EuiIcon,
    EuiSpacer,
    formatDate,
    EuiEmptyPrompt,
    EuiLoadingLogo,
    EuiBasicTable,
} from '@elastic/eui';
import {useHistory} from "react-router-dom";

import {useInfrastructures} from "../../context/infrastructures";
import InfrastructureModal from "../../components/InfrastructureModal";

const EmptyInfrastructures = () => {
    return (<EuiEmptyPrompt
        iconType="faceSad"
        title={<h2>No infrastructures here yet...</h2>}
        body={<p>Wait for the UniQs to produce some data!</p>}

    />);
}

const LoadingInfrastructures = () => {
    return (<EuiEmptyPrompt
        icon={<EuiLoadingLogo logo="dashboardApp" size="xl"/>}
        title={<h2>Loading infrastructures...</h2>}
    />)
}

const ErrorLoading = () => {

    const settings = {
        color: 'danger', iconType: 'alert', title: <h2>Unable to load your infrastructures</h2>, body: (<p>
            There was an error loading the data. Please retry or otherwise contact Jasper for help ;)
        </p>),
    }
    return (<EuiEmptyPrompt {...settings} />)
}

const InfrastructuresPage = (props) => {

    const {getMyInfrastructures, addInfrastructure} = useInfrastructures()
    const [infrastructures, setInfrastructures] = useState([])
    const [selectedInfra, setSelectedInfra] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [loading, setLoading] = useState(true)


    const history = useHistory();

    const reset = () => {
        setLoading(true)
        setError(undefined)
        setInfrastructures([])
        setSelectedInfra(undefined)
        getMyInfrastructures().then(infrastructures => {
            setLoading(false)
            setInfrastructures(infrastructures)
        })
            .catch(error => {
                setLoading(false)
                setError(error)
            });
    }

    useEffect(() => {
        reset()
    }, [])

    const columns = [{
        field: 'name', name: 'Name', sortable: true, 'data-test-subj': 'nameCell', width: '20%'
        // render: (item) => item.name,
    }, {
        field: 'lat', name: 'Latitude', sortable: false, 'data-test-subj': 'latCell', width: '30%',
        render: (item) => item.toString().substring(0, 6),
    }, {
        field: 'lng', name: 'Longitude', sortable: false, 'data-test-subj': 'lngCell', width: '30%',
        render: (item) => item.toString().substring(0, 6),
    }, {
        name: 'actions',
        actions: [
            {
                name: 'Link to entity',
                render: (item) => (
                    <EuiIcon type='link' onClick={() => history.push(`/infrastructures/${item.id}`)}/>
                )
            },
        ]
    }]

    const getRowProps = (item) => {
        const {id} = item;
        return {
            'data-test-subj': `row-${id}`, className: 'customRowClass', onClick: () => {
                setSelectedInfra(item)
            },
        };
    };

    const getCellProps = (item, column) => {
        const {id} = item;
        const {field} = column;
        return {
            className: 'customCellClass', 'data-test-subj': `cell-${id}-${field}`, textOnly: true,
        };
    };

    return (
        <EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType="visualizeApp"
                pageTitle="Infrastructures"
                description="Choose an infrastructure to inspect"
            />

            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >

                <EuiPageContentBody restrictWidth={'75%'}>
                    {!loading && error && (<ErrorLoading/>)}
                    {loading && (<LoadingInfrastructures/>)}
                    {!loading && !error && infrastructures.length === 0 && (<EmptyInfrastructures/>)}
                    {!loading && !error && infrastructures.length > 0 && (<EuiFlexGroup gutterSize="xl">
                        <EuiFlexItem grow={6}>
                            <EuiBasicTable
                                // tableCaption="Demo of EuiBasicTable"
                                items={infrastructures}
                                // rowHeader="firstName"
                                columns={columns}
                                rowProps={getRowProps}
                                cellProps={getCellProps}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={4}>
                            {selectedInfra && (<div>
                                <EuiText>
                                    <h2>{selectedInfra.name}</h2>
                                </EuiText>
                                <EuiSpacer size="m"/>
                                <EuiImage
                                    size={300}
                                    hasShadow
                                    allowFullScreen
                                    alt={selectedInfra.name}
                                    src={selectedInfra.b64_image}

                                />
                                <EuiSpacer size="m"/>
                                <EuiText>
                                    <b>Last updated: </b> {formatDate(selectedInfra.updatedAt)} <br/>
                                    <b>Created on: </b> {formatDate(selectedInfra.createdAt)}
                                </EuiText>

                            </div>)
                            }
                            {!selectedInfra && (<EuiFlexGroup justifyContent="center"
                                                              alignItems="center">
                                <EuiFlexItem grow={false}>
                                    Select an infrastructure
                                </EuiFlexItem>
                            </EuiFlexGroup>)
                            }
                        </EuiFlexItem>
                    </EuiFlexGroup>)
                    }
                    <EuiSpacer size="m"/>
                    <InfrastructureModal iconType="plusInCircleFilled" mode="Create" text="Create an infrastructure"
                                         onSubmit={(infra) => {
                                             addInfrastructure(infra).then(_ => {
                                                 reset()
                                             })
                                         }}/>


                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>)
}

export default InfrastructuresPage;


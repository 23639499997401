import React, {useEffect, useState} from 'react'
import {
    EuiPanel,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiFlexItem, EuiButtonIcon,
    EuiDatePicker,
    EuiSelectable, EuiButton, EuiCallOut, EuiSpacer, EuiFieldNumber, EuiFormRow, EuiForm,
} from "@elastic/eui"
import moment from "moment";


const UniqSchedulingPanel = ({uniq, localTimeslots, setLocalTimeslots, setEdited}) => {

    const WEEKDAYS = [{
        label: 'Monday',
    }, {
        label: 'Tuesday',
    }, {
        label: 'Wednesday',
    }, {
        label: 'Thursday',
    }, {
        label: 'Friday',
    }, {
        label: 'Saturday',
    }, {
        label: 'Sunday',
    }]

    const emptyTimeslot = {
        start_time_hour: 0,
        start_time_minutes: 0,
        stop_time_hour: 0,
        stop_time_minutes: 0,
        week_day: 1,
        transmit_interval: 1000
    }

    const refresh = () => {
        setLocalTimeslots(uniq.settings.timeslots)
    }
    const renderTimeslot = (currentTimeslots, timeslot, index) => (
        <EuiFlexItem direction="column" key={index}>
            <EuiPanel hasBorder={true}>
                <EuiFlexItem>
                    <EuiButtonIcon
                        iconType="minusInCircleFilled"
                        onClick={() => {
                            setLocalTimeslots(
                                localTimeslots.filter((_, i) => i !== index)
                            )
                        }}/>
                </EuiFlexItem>
                <EuiSpacer size="m"/>
                <EuiForm>
                    <EuiFormRow display="columnCompressed" label="From">
                        <EuiDatePicker
                            compressed={true}
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            selected={moment().hours(timeslot['start_time_hour']).minutes(timeslot['start_time_minutes'])}
                            onChange={time => {
                                let newTimeslot = {
                                    ...timeslot,
                                    start_time_hour: time.hour(),
                                    start_time_minutes: time.minutes()
                                }
                                setLocalTimeslots(
                                    Object.assign([], localTimeslots, {[index]: newTimeslot})
                                )
                                setEdited(true)

                            }}
                        />
                    </EuiFormRow>
                    <EuiFormRow display="columnCompressed" label="From">
                        <EuiDatePicker
                            compressed={true}
                            showTimeSelect
                            showTimeSelectOnly
                            selected={moment().hours(timeslot['stop_time_hour']).minutes(timeslot['stop_time_minutes'])}
                            onChange={time => {

                                let newTimeslot = {
                                    ...timeslot,
                                    stop_time_hour: time.hour(),
                                    stop_time_minutes: time.minutes()
                                }
                                setLocalTimeslots(
                                    Object.assign([], localTimeslots, {[index]: newTimeslot})
                                )
                                setEdited(true)
                            }}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                        /></EuiFormRow>
                    <EuiFormRow display="columnCompressed" label="On">
                        <EuiSelectable
                            compressed={true}
                            checked={true}
                            options={Object.assign([], WEEKDAYS, {
                                [timeslot['week_day'] - 1]: {
                                    ...WEEKDAYS[timeslot['week_day'] - 1],
                                    'checked': 'on'
                                }
                            })}
                            onChange={newSelection => {
                                const checkedIndex = newSelection.findIndex(o => o.checked === 'on')

                                const newTimeslot = {
                                    ...timeslot, week_day: checkedIndex + 1
                                }

                                setLocalTimeslots(Object.assign([], localTimeslots, {[index]: newTimeslot}))
                                setEdited(true)
                            }}
                            singleSelection='always'
                            listProps={{bordered: true}}
                        >
                            {(list) => list}
                        </EuiSelectable>

                    </EuiFormRow>
                    <EuiFormRow display="columnCompressed" label="Interval in seconds">
                        <EuiFieldNumber
                            compressed={true}
                            onChange={event => {
                                const newTimeslot = {
                                    ...timeslot, transmit_interval: parseInt(event.target.value)
                                }
                                setLocalTimeslots(Object.assign([], localTimeslots, {[index]: newTimeslot}))
                                setEdited(true)
                            }} value={timeslot.transmit_interval}/>

                    </EuiFormRow>
                </EuiForm>
            </EuiPanel>
        </EuiFlexItem>)

    return (
        <EuiPanel hasShadow={false}>
            <EuiFlexGroup direction="column">
                <EuiFlexGroup direction="row">
                    {!!localTimeslots && <>
                        <EuiFlexItem> Timeslots ({localTimeslots.length} / 25)</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton color="primary" iconType="plusInCircleFilled" onClick={() => {
                                if (localTimeslots === undefined) return;
                                if (localTimeslots.length >= 25) return;
                                setLocalTimeslots([...localTimeslots, emptyTimeslot])
                            }}>
                                Add new timeslot
                            </EuiButton>
                        </EuiFlexItem>

                        <EuiFlexItem grow={false}>
                            <EuiButton fill
                                       iconType="refresh" aria-label="refresh" color="primary" onClick={() => {
                                refresh()
                            }}>Refresh</EuiButton>
                        </EuiFlexItem>
                    </>}
                </EuiFlexGroup>
                <EuiSpacer size="l"/>
                <EuiFlexGrid columns={3}>
                    {localTimeslots.map((item, i) => renderTimeslot(localTimeslots, item, i))}
                </EuiFlexGrid>
            </EuiFlexGroup>
        </EuiPanel>)
}

export default UniqSchedulingPanel;
import React from 'react'
import {EuiDatePicker, EuiFieldNumber, EuiFormRow, EuiFilePicker, useGeneratedHtmlId} from "@elastic/eui"
import QBFSettingsFlyOut from "../QBFSettingsFlyOut";
import RawUniqMeasurementSelectionFlyOut from "../RawUniqMeasurementSelectionFlyOut";

const ParameterForm = ({values, parameters, onChange}) => {

    const filePickerId = useGeneratedHtmlId({prefix: 'filePicker'});


    const renderParameter = (parameter, index) => {
        switch (parameter.type) {
            case "number":
                return (<EuiFieldNumber
                    placeholder={0}
                    value={values[parameter.name] || 0}
                    onChange={(e) => {
                        onChange({...values, [parameter.name]: parseInt(e.target.value) || 0})
                    }}
                />)
            case "date":
            case "datetime":
                return (
                    <EuiDatePicker
                        selected={values[parameter.name]}
                        onChange={(e) => onChange({...values, [parameter.name]: e})}
                    />
                )
            case "qbf_setting":
                return (
                    <QBFSettingsFlyOut
                        current={values[parameter.name]}
                        onChange={(e) => onChange({...values, [parameter.name]: e})}
                    />
                )
            case "file":
                return (
                    <div>
                        <EuiFilePicker
                            id={filePickerId}
                            multiple
                            initialPromptText="Select the csv file"
                            onChange={(files) => {
                                const file = Array.from(files)[0];
                                file.text().then(t => {
                                    onChange({
                                        ...values,
                                        [parameter.name]: {
                                            name: file.name,
                                            "content": t
                                        }
                                    })
                                })

                            }}
                            display={'default'}
                        />
                    </div>
                );
            case "waveform_range":
                return (
                    <RawUniqMeasurementSelectionFlyOut
                        onChange={(e) => onChange({...values, [parameter.name]: e})}/>
                )
            default:
                return (
                    <div key={index}>{}</div>
                );
        }
    }

    return (
        <>
            {parameters.map((parameter, index) => (
                <EuiFormRow label={parameter.label} key={index}>
                    {renderParameter(parameter, index)}
                </EuiFormRow>
            ))}
        </>
    );


}

export default ParameterForm;
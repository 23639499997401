import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageBody,
    EuiTitle,
    EuiLink,
    EuiImage,
    EuiSpacer,
    EuiLoadingSpinner,
    EuiAspectRatio,
    EuiPanel,
    EuiIcon,
    EuiFlexGrid,
    EuiFlexItem
} from '@elastic/eui';
import shmnextIcon from './shmnext.svg';
import {useHistory} from "react-router-dom";
import {useInfrastructures} from "../../context/infrastructures";


const MonitoringPage = () => {

    const {getMyFavoritedInfrastructures} = useInfrastructures()
    const [infrastructures, setInfrastructures] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
        setLoading(true)
        getMyFavoritedInfrastructures().then(infra => {
            setInfrastructures(infra)
            setLoading(false)
        })
    }, [])
    return (
        <EuiPageBody panelled>
            <EuiPageHeader
                restrictWidth={'75%'}
                iconType={shmnextIcon}
                pageTitle="Monitoring"
                description="Realtime insights into the health of the system"
            />
            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    <EuiTitle><h2>My pinned infrastructures</h2></EuiTitle>
                    <EuiSpacer size="m"/>
                    <EuiFlexGrid columns={3}>
                        {infrastructures && infrastructures.map((infra, index) => {
                            return (
                                <EuiFlexItem key={index}>
                                    <EuiPanel hasBorder={true} hasShadow={false}>
                                        <EuiLink onClick={() => history.push(`/infrastructures/${infra.id}`)}>
                                            <EuiTitle><h3>{infra.name}</h3></EuiTitle></EuiLink>
                                        <EuiSpacer size="m"/>
                                        {infra.b64_image && (<EuiAspectRatio width={21} height={9}>
                                                <EuiImage
                                                    size={300}
                                                    hasShadow
                                                    allowFullScreen
                                                    alt={infra.name}
                                                    src={infra.b64_image}
                                                />
                                            </EuiAspectRatio>
                                        )}
                                    < /EuiPanel>
                                </EuiFlexItem>
                            )
                        })}
                        {loading && <EuiLoadingSpinner/>}
                    </EuiFlexGrid>
                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>
    )
        ;
};

export default MonitoringPage;

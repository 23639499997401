import {EuiSpacer, EuiText, EuiTitle} from "@elastic/eui";
import React from "react";


const ChangeLog = () => (
    <>
        <EuiTitle>
            <h2>Changelog</h2>
        </EuiTitle>
        <EuiSpacer size="m"/>
        <EuiText>
            <h3>1.0.2 - Current</h3>
            Added waveform range selection.
        </EuiText>
        <EuiText>
            <h3>1.0.1</h3>
            Added QBF setting selection.
        </EuiText>
        <EuiText>
            <h3>1.0.0</h3>
            Added QBF processing and seeing the outputs.
        </EuiText>
        <EuiText>
            <h3>0.1.0 - Initial release</h3>
            Added first iteration of homepage, navigation and page layout.
        </EuiText>
    </>
)

export default ChangeLog;
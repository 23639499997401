import React from 'react'
import axios from 'axios'
import {API_BASE_URL, SHMNEXT_API_URL} from "../config";
import {useAuthentication} from "./authentication";
import moment from "moment";


const RawUniqMeasurementContext = React.createContext({
    getCompleteRawUniqMeasurementById: () => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    }, getRawUniqMeasurementsByUniqLocationPaged: () => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    }, download: (filter) => {

    }
})

const RawUniqMeasurementProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const getRawUniqMeasurementsByUniqLocationPaged = (uniq_location, page, size = 10, filters) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${uniq_location["_links"]["raw_uniq_measurements"]["href"]}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }, params: {
                    'size': size,
                    'page': page,
                    'snr': filters.snr,
                    'start_moment': moment(filters.start_moment || null).isValid() ? moment(filters.start_moment).format("YYYY-MM-DD") : undefined,
                    'stop_moment': moment(filters.stop_moment || null).isValid() ? moment(filters.stop_moment).format("YYYY-MM-DD") : undefined
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }
    const getCompleteRawUniqMeasurementById = (id) => {
        return new Promise((resolve, reject) => {
            const url = `${SHMNEXT_API_URL}/raw_uniq_measurements/id/${id}`
            return axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                },
            })
                .then(r => {
                    resolve(r.data)
                })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }


    const download = (filter, type) => {
        const url = `${SHMNEXT_API_URL}/raw_uniq_measurements/${type}/${filter.uniq_location_id}`

        return new Promise((resolve, reject) => {

            axios.post(url, {
                ...filter,
                'start_moment': moment(filter.start_moment || null).isValid() ? moment(filter.start_moment).format("YYYY-MM-DD") : undefined,
                'stop_moment': moment(filter.stop_moment || null).isValid() ? moment(filter.stop_moment).format("YYYY-MM-DD") : undefined
            }, {
                responseType: 'blob', headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                    Accept: type === 'zip' ? 'application/zip' : 'application/json',
                }
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `measurements.${type}`); //or any other extension
                document.body.appendChild(link);
                link.click();
                resolve()
            }).catch(reason => {
                logout();
                reject()
            })
        });

    }


    return (<RawUniqMeasurementContext.Provider
        value={{
            getRawUniqMeasurementsByUniqLocationPaged, getCompleteRawUniqMeasurementById, download
        }} {...props} />)

}
const useRawUniqMeasurements = () => React.useContext(RawUniqMeasurementContext)
export {RawUniqMeasurementProvider, useRawUniqMeasurements,}


import {
    EuiModal,
    EuiForm,
    EuiButton,
    EuiFormRow,
    EuiDatePickerRange,
    EuiModalBody,
    EuiDatePicker,
    EuiModalFooter,
    EuiSpacer,
    EuiButtonEmpty,
    EuiModalHeader,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiAccordion, EuiFlexItem, EuiTitle, EuiFilePicker
} from "@elastic/eui";
import React, {useRef, useState} from "react";
import {useUniqLocations} from "../../context/uniqLocations";
import EuiTextAsNumberField from "../EuiTextAsNumberField";
import {useInfrastructures} from "../../context/infrastructures";


const InfrastructureModal = ({initial, mode, text, iconType, onSubmit}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCommitting, setIsCommitting] = useState(false);
    const [currentInfrastructure, setCurrentInfrastructure] = useState({
        'name': initial?.name,
        'origin': {
            'x': initial?.origin?.x || 0,
            'y': initial?.origin?.y || 0,
            'z': initial?.origin?.z || 0
        },
        'b64_image': undefined,
        'lat': initial?.lat || 0,
        'lng': initial?.lng || 0,
        'rotation': {
            'q1': initial?.rotation?.q1 || 0,
            'q2': initial?.rotation?.q2 || 0,
            'q3': initial?.rotation?.q3 || 0,
            'q4': initial?.rotation?.q4 || 0
        },

    })
    const [currentDisplayInfrastructure, setCurrentDisplayInfrastructure] = useState({
            'name': initial?.name,
            'origin': {
                'x': initial?.origin?.x || 0,
                'y': initial?.origin?.y || 0,
                'z': initial?.origin?.z || 0
            },
            'b64_image': undefined,
            'lat': initial?.lat || 0,
            'lng': initial?.lng || 0,
            'rotation': {
                'q1': initial?.rotation?.q1 || 0,
                'q2': initial?.rotation?.q2 || 0,
                'q3': initial?.rotation?.q3 || 0,
                'q4': initial?.rotation?.q4 || 0
            },

        },
    )

    const {addInfrastructure} = useInfrastructures();

    const ModalFormId = useGeneratedHtmlId({prefix: 'InfrastructureCreationModal'});

    const closeModal = () => {
        setCurrentInfrastructure({})
        setIsModalVisible(false);
    }

    function onChange(display, parsed) {
        setCurrentDisplayInfrastructure(display)
        setCurrentInfrastructure(parsed)
    }

    const filePickerRef = useRef();

    const form = () => {
        return (
            <EuiForm id={ModalFormId} component="form"
                     onSubmit={() => {
                         setIsCommitting(true)
                         onSubmit(currentInfrastructure)
                         setIsCommitting(false)
                         closeModal()
                     }}>
                <EuiFormRow key='datasetName' label="Name" helpText='The name of the Infrastructure.'>
                    <EuiFieldText value={currentDisplayInfrastructure['name']}
                                  onChange={e => {
                                      onChange(
                                          {...currentDisplayInfrastructure, 'name': e.target.value},
                                          {...currentInfrastructure, 'name': e.target.value}
                                      )
                                  }}/>
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiFormRow>
                    <EuiFilePicker ref={filePickerRef} initialPromptText={"Select an image."} display={'default'}
                                   onChange={(files) => {
                                       const image = files[0]
                                       if (image && image.type === 'image/png') {
                                           const reader = new FileReader();

                                           reader.onloadend = () => {
                                               setCurrentInfrastructure({
                                                   ...currentInfrastructure,
                                                   'b64_image': reader.result
                                               })
                                           };
                                           reader.readAsDataURL(image);

                                       } else if (image) {
                                           alert("Please select a .png file")
                                           filePickerRef.current.removeFiles()
                                       }


                                   }}/>
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Location</h3>
                            </EuiTitle>
                        </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="X" helpText="Distance on x-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['origin']['x']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'origin': {'x': display}
                                                      },
                                                      {...currentInfrastructure, 'origin': {'x': parsed}}
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Y" helpText="Distance on y-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['origin']['y']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'origin': {'y': display}
                                                      }, {
                                                          ...currentInfrastructure,
                                                          'origin': {'y': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Z" helpText="Distance on z-axis from infrastructure origin">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['origin']['z']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'origin': {'z': display}
                                                      }, {
                                                          ...currentInfrastructure,
                                                          'origin': {'z': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Rotation</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    }
                    paddingSize="l"
                >
                    <EuiFormRow label="Q1" helpText="Quaternion - value 1">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['rotation']['q1']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'rotation': {'q1': display}
                                                      },
                                                      {
                                                          ...currentInfrastructure,
                                                          'rotation': {'q1': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q2" helpText="Quaternion - value 2">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['rotation']['q2']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'rotation': {'q2': display}
                                                      },
                                                      {
                                                          ...currentInfrastructure,
                                                          'rotation': {'q2': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q3" helpText="Quaternion - value 3">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['rotation']['q3']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'rotation': {'q3': display}
                                                      },
                                                      {
                                                          ...currentInfrastructure,
                                                          'rotation': {'q3': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q4" helpText="Quaternion - value 4">
                        <EuiTextAsNumberField value={currentDisplayInfrastructure['rotation']['q4']}
                                              onChange={(display, parsed) => {
                                                  onChange(
                                                      {
                                                          ...currentDisplayInfrastructure,
                                                          'rotation': {'q4': display}
                                                      },
                                                      {
                                                          ...currentInfrastructure,
                                                          'rotation': {'q4': parsed}
                                                      }
                                                  );
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={
                        <EuiFlexItem>
                            <EuiTitle size="xs">
                                <h3>Global coordinates</h3>
                            </EuiTitle>
                        </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="latitude" helpText="Latitude">
                        <EuiTextAsNumberField
                            value={currentDisplayInfrastructure['lat']}
                            onChange={(display, parsed) => {
                                onChange(
                                    {
                                        ...currentDisplayInfrastructure,
                                        'lat': display
                                    },
                                    {...currentInfrastructure, 'lat': parsed}
                                );
                            }}/>
                    </EuiFormRow>
                    <EuiFormRow label="lng" helpText="Longitude">
                        <EuiTextAsNumberField
                            value={currentDisplayInfrastructure['lng']}
                            onChange={(display, parsed) => {
                                onChange(
                                    {
                                        ...currentDisplayInfrastructure,
                                        'lng': display
                                    },
                                    {...currentInfrastructure, 'lng': parsed}
                                );
                            }}/>
                    </EuiFormRow>
                </EuiAccordion>
            </EuiForm>
        )
    };


    const Modal = (
        <EuiModal style={{width: 400}} onClose={closeModal} initialFocus="[name=popswitch]">
            <EuiModalHeader>
                <h1>{mode} an Infrastructure</h1>
            </EuiModalHeader>

            <EuiModalBody>
                {form()}
            </EuiModalBody>

            <EuiModalFooter>

                <EuiButtonEmpty onClick={() => {
                    closeModal()
                }}>Cancel</EuiButtonEmpty>

                {!isCommitting && (<EuiButton
                    isDisabled={currentInfrastructure['name'] === undefined}
                    type="submit"
                    form={ModalFormId} fill>
                    {mode}
                </EuiButton>)}
                {isCommitting && (<EuiLoadingSpinner s='m'/>)}

            </EuiModalFooter>
        </EuiModal>
    );


    const showModal = () => setIsModalVisible(true);
    return (
        <>
            <EuiButton size="m" display="fill" iconType={iconType} onClick={showModal}>{text}</EuiButton>
            {isModalVisible && Modal}
        </>
    );
}

export default InfrastructureModal;
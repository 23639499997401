import React, {useEffect, useState} from 'react';
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageBody,
    EuiSpacer,
    EuiHorizontalRule, EuiLoadingSpinner, EuiCallOut, EuiButton, EuiText,
} from '@elastic/eui';


import {useUniqs} from "../../context/uniqs";
import shmnextIcon from "../home/shmnext.svg";
import Segment from "../../components/Segment";
import UniqSettingsPanel from "../../components/UniqSettingsPanel";
import UniqSchedulingPanel from "../../components/UniqSchedulingPanel";

import UniqTable from "../../components/UniqTable";
import {useUniqLocations} from "../../context/uniqLocations";
import BatteryGraphChart from "../../components/BatteryGraphChart";


const UniqsPage = (props) => {

    const {getMyUniqs, getUniqById, updateSettings, getLatestStatusByUniq} = useUniqs()
    const {getUniqLocationByRelatedObject} = useUniqLocations();

    const [uniqs, setUniqs] = useState([])
    const [selectedUniq, setSelectedUniq] = useState(undefined)

    const [edited, setEdited] = useState(false);
    const [localTimeslots, setLocalTimeslots] = useState([]);
    const [localSettings, setLocalSettings] = useState({})
    const [updateSuccessful, setSuccess] = useState(undefined)

    const [uniqLocationsForUniqs, setUniqLocationsForUniqs] = useState(undefined);
    const [batteryStatusesForUniqs, setBatteryStatusesForUniqs] = useState(undefined);


    const [loadingAll, setLoadingAll] = useState(true)
    const [loadingSingle, setLoadingSingle] = useState(false)

    const reset = () => {
        setLoadingAll(true)
        setUniqs([])
        setSelectedUniq(undefined)
        getMyUniqs().then(uniqs => {

            setUniqs(uniqs)
            loadUniqLocationsForUniqs(uniqs).then(() => {
                loadBatteryStatusForUniqs(uniqs).then(() => {
                    setLoadingAll(false)
                })
            })
        })
            .catch(error => {

            });
    }
    const loadUniqLocationsForUniqs = (uniqs) => {
        return new Promise((res, rej) => {
            Promise.all(uniqs.map(uniq => {
                return new Promise((resolve, reject) => getUniqLocationByRelatedObject(uniq).then((v) => resolve([uniq.id, v])))
            })).then((values) => {
                    setUniqLocationsForUniqs(
                        values.reduce((acc, vals) => {
                            return {...acc, [vals[0]]: vals[1]}
                        }, {})
                    );
                    res()
                }
            );
        });
    }

    const loadBatteryStatusForUniqs = (uniqs) => {
        return new Promise((res, rej) => {
            Promise.all(uniqs.map(uniq => {
                return new Promise((resolve, reject) => getLatestStatusByUniq(uniq).then((v) => resolve([uniq.id, v])))
            })).then((values) => {
                    setBatteryStatusesForUniqs(
                        values.reduce((acc, vals) => {
                            return {...acc, [vals[0]]: vals[1]}
                        }, {}));
                    res();
                }
            );
        });
    }


    const loadSelectedUniqById = (uniqId) => {
        if (uniqId !== undefined) {
            setLoadingSingle(true);
            getUniqById(uniqId).then(uniq => {
                const {settings: {timeslots}} = uniq;

                setLocalTimeslots(timeslots)
                setLocalSettings({...uniq.settings, timeslots: undefined});

                setSelectedUniq(uniq)

                setLoadingSingle(false);
            });
        }
    }

    useEffect(() => {
        reset()
    }, [])

    return (<EuiPageBody panelled>

            <EuiPageHeader
                restrictWidth={'75%'}
                iconType={shmnextIcon}
                pageTitle={`UniQs - Configuration and history`}
                description="Change UniQ settings and timetables and see their history."

            />

            <EuiPageContent
                paddingSize="none"
                color="transparent"
                borderRadius="none"
            >
                <EuiPageContentBody restrictWidth={'75%'}>
                    <EuiText>
                        <h2>Select a UniQ</h2>
                    </EuiText>
                    <EuiSpacer size="m"/>
                    {loadingAll && <EuiLoadingSpinner size="l"/>}
                    {!loadingAll && (
                        <UniqTable uniqs={uniqs}
                                   uniqLocations={uniqLocationsForUniqs}
                                   batteryStatus={batteryStatusesForUniqs}
                                   selectedUniqId={selectedUniq ? selectedUniq.id : undefined}
                                   onSelect={(uniq) => {
                                       loadSelectedUniqById(uniq.id)
                                   }}/>)}
                    <EuiHorizontalRule/>
                    {loadingSingle && (<EuiLoadingSpinner size='l'/>)}
                    {updateSuccessful === true && <>
                        <EuiCallOut title="Update successful." color="success" iconType="save"/>
                        <EuiSpacer size="m"/>
                    </>}
                    {updateSuccessful === false && <>
                        <EuiCallOut title="An error occurred while updating." color="danger" iconType="save"/>
                        <EuiSpacer size="m"/>
                    </>}
                    {!loadingSingle && selectedUniq !== undefined && (<>
                            <EuiSpacer size="l"/>
                            <Segment title="Battery graph">
                                <BatteryGraphChart uniq={selectedUniq}/>
                            </Segment>
                            <EuiSpacer size="l"/>
                            <EuiButton iconType="save" color="primary" isDisabled={!edited} onClick={() => {
                                console.log(localSettings)
                                updateSettings(selectedUniq.id, {
                                    ...localSettings,
                                    'timeslots': localTimeslots,
                                }).then(() => {
                                    setSuccess(true)
                                }).catch(() => {
                                    setSuccess(false)
                                })
                            }}>Save</EuiButton>
                            <EuiSpacer size="l"/>
                            <EuiFlexGroup direction="row">
                                <EuiFlexItem grow={1}>
                                    <Segment title="Settings">{selectedUniq && (
                                        <UniqSettingsPanel uniq={selectedUniq}
                                                           localSettings={localSettings}
                                                           setLocalSettings={setLocalSettings}
                                                           edited={edited}
                                                           setEdited={setEdited}
                                        />)}
                                    </Segment>
                                </EuiFlexItem>

                                <EuiFlexItem grow={4}>
                                    <Segment title="Scheduling">{selectedUniq && (
                                        <UniqSchedulingPanel uniq={selectedUniq}
                                                             setLocalTimeslots={setLocalTimeslots}
                                                             localTimeslots={localTimeslots}
                                                             edited={edited}
                                                             setEdited={setEdited}/>)}
                                    </Segment>
                                </EuiFlexItem>

                            </EuiFlexGroup>

                        </>
                    )}
                </EuiPageContentBody>
            </EuiPageContent>
        </EuiPageBody>
    )


}

export default UniqsPage;


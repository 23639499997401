import {
    EuiModal,
    EuiForm,
    EuiButton,
    EuiFormRow,
    EuiDatePickerRange,
    EuiModalBody,
    EuiDatePicker,
    EuiModalFooter,
    EuiSpacer,
    EuiButtonEmpty,
    EuiModalHeader,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiLoadingSpinner,
    EuiAccordion,
    EuiFlexItem,
    EuiTitle
} from "@elastic/eui";
import React, {useState} from "react";
import EuiTextAsNumberField from "../EuiTextAsNumberField";
import moment from "moment/moment";


const UniqLocationModal = ({text, buttonText, buttonIcon, buttonSize, onSubmit, initialLocation}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCommitting, setIsCommitting] = useState(false);

    const initialWithDefaults = {
        'name': initialLocation?.name || "",
        'start_monitoring_moment': initialLocation?.start_monitoring_moment || undefined,
        'stop_monitoring_moment': initialLocation?.stop_monitoring_moment || undefined,
        'position': {
            'x': initialLocation?.position?.position?.x || 0,
            'y': initialLocation?.position?.position?.y || 0,
            'z': initialLocation?.position?.position?.z || 0
        },
        'rotation': {
            'q1': initialLocation?.position?.rotation?.q1 || 0,
            'q2': initialLocation?.position?.rotation?.q2 || 0,
            'q3': initialLocation?.position?.rotation?.q3 || 0,
            'q4': initialLocation?.position?.rotation?.q4 || 0
        },
        'location': initialLocation?.location || "",
    };


    const [currentUniqLocation, setCurrentUniqLocation] = useState(initialWithDefaults)
    const [currentDisplayUniqLocation, setCurrentDisplayUniqLocation] = useState(initialWithDefaults)

    const ModalFormId = useGeneratedHtmlId({prefix: 'UniqLocationGroupCreationModal'});

    const closeModal = () => {
        setIsModalVisible(false);
    }

    function onChange(display, parsed) {
        setCurrentDisplayUniqLocation(display)
        setCurrentUniqLocation(parsed)
    }

    const form = () => {
        const startDate = currentDisplayUniqLocation['start_monitoring_moment'] !== undefined ? moment.utc(currentDisplayUniqLocation['start_monitoring_moment'], 'YYYY-MM-DD') : undefined
        const endDate = currentDisplayUniqLocation['stop_monitoring_moment'] !== undefined ? moment.utc(currentDisplayUniqLocation['stop_monitoring_moment'], 'YYYY-MM-DD') : undefined
        return (<EuiForm id={ModalFormId} component="form"
                         onSubmit={() => {
                             setIsCommitting(true)
                             onSubmit(currentUniqLocation)
                             setIsCommitting(false);
                             closeModal()
                         }}>
                <EuiFormRow key='name' label="Name" helpText='The name of the UniQ location.'>
                    <EuiFieldText value={currentDisplayUniqLocation['name']}
                                  onChange={e => {
                                      onChange({
                                          ...currentDisplayUniqLocation,
                                          'name': e.target.value
                                      }, {...currentUniqLocation, 'name': e.target.value})
                                  }}/>
                </EuiFormRow>
                <EuiFormRow key='location' label="Location"
                            helpText='A free form field to note the place where the location is on the bridge'>
                    <EuiFieldText value={currentDisplayUniqLocation['location']}
                                  onChange={e => {
                                      onChange({
                                          ...currentDisplayUniqLocation,
                                          'location': e.target.value
                                      }, {...currentUniqLocation, 'location': e.target.value})
                                  }}/>
                </EuiFormRow>
                <EuiFormRow key='datasetTimeRange'
                            label="Monitoring time range"
                            helpText='The planned range of dates that this group will be monitored. Inclusive at both ends and will propagate as default to locations in the group.'>
                    <EuiDatePickerRange
                        isInvalid={startDate > endDate}
                        startDateControl={<EuiDatePicker
                            selected={startDate}
                            onChange={(date) => date && onChange({
                                ...currentDisplayUniqLocation, 'start_monitoring_moment': date
                            }, {
                                ...currentUniqLocation, 'start_monitoring_moment': date
                            })}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="YYYY-MM-DD"
                            aria-label="Start date"
                        />}
                        endDateControl={<EuiDatePicker
                            selected={endDate}
                            onChange={(date) => date && onChange({
                                ...currentDisplayUniqLocation, 'stop_monitoring_moment': date
                            }, {
                                ...currentUniqLocation, 'stop_monitoring_moment': date
                            })}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="YYYY-MM-DD"
                            aria-label="End date"
                        />}
                    />
                </EuiFormRow>
                <EuiSpacer size="m"/>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={<EuiFlexItem>
                        <EuiTitle size="xs">
                            <h3>Relative position to group origin</h3>
                        </EuiTitle>
                    </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="X" helpText="Distance on x-axis from group origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['position']['x']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation,
                                                      'x': display
                                                  }, {...currentUniqLocation, 'x': parsed});
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Y" helpText="Distance on y-axis from group origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['position']['y']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation,
                                                      'y': display
                                                  }, {...currentUniqLocation, 'y': parsed});
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Z" helpText="Distance on z-axis from group origin">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['position']['z']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation,
                                                      'z': display
                                                  }, {...currentUniqLocation, 'z': parsed});
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
                <EuiAccordion
                    element="fieldset"
                    className="euiAccordionForm"
                    buttonClassName="euiAccordionForm__button"
                    buttonContent={<EuiFlexItem>
                        <EuiTitle size="xs">
                            <h3>Relative rotation to group rotation</h3>
                        </EuiTitle>
                    </EuiFlexItem>}
                    paddingSize="l"
                >
                    <EuiFormRow label="Q1" helpText="Quaternion - value 1">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['rotation']['q1']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation, 'rotation': {'q1': display}
                                                  }, {
                                                      ...currentUniqLocation, 'rotation': {'q1': parsed}
                                                  });
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q2" helpText="Quaternion - value 2">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['rotation']['q2']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation, 'rotation': {'q2': display}
                                                  }, {
                                                      ...currentUniqLocation, 'rotation': {'q2': parsed}
                                                  });
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q3" helpText="Quaternion - value 3">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['rotation']['q3']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation, 'rotation': {'q3': display}
                                                  }, {
                                                      ...currentUniqLocation, 'rotation': {'q3': parsed}
                                                  });
                                              }}/>
                    </EuiFormRow>
                    <EuiFormRow label="Q4" helpText="Quaternion - value 4">
                        <EuiTextAsNumberField value={currentDisplayUniqLocation['rotation']['q4']}
                                              onChange={(display, parsed) => {
                                                  onChange({
                                                      ...currentDisplayUniqLocation, 'rotation': {'q4': display}
                                                  }, {
                                                      ...currentUniqLocation, 'rotation': {'q4': parsed}
                                                  });
                                              }}/>
                    </EuiFormRow>
                </EuiAccordion>
            </EuiForm>)
    };


    const Modal = (<EuiModal style={{width: 400}} onClose={closeModal} initialFocus="[name=popswitch]">
            <EuiModalHeader>
                <h1>{text}</h1>
            </EuiModalHeader>

            <EuiModalBody>
                {form()}
            </EuiModalBody>

            <EuiModalFooter>

                <EuiButtonEmpty onClick={() => {
                    closeModal()
                }}>Cancel</EuiButtonEmpty>

                {!isCommitting && (<EuiButton
                    isDisabled={currentUniqLocation['name'] === undefined}
                    type="submit"
                    form={ModalFormId} fill>
                    {buttonText}
                </EuiButton>)}
                {isCommitting && (<EuiLoadingSpinner s='m'/>)}

            </EuiModalFooter>
        </EuiModal>);


    const showModal = () => setIsModalVisible(true);
    return (<>
            <EuiButton size={buttonSize || "s"} display="fill" iconType={buttonIcon || "plus"}
                       onClick={showModal}>{text}</EuiButton>
            {isModalVisible && Modal}
        </>);
}

export default UniqLocationModal;
import {createClassFromSpec} from "react-vega";

const HistogramSelectionChart = createClassFromSpec({
    spec: {
        "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
        "description": "Bar chart for histogram with brushing signal",
        "data": {"name": "table"},

        "width": 500,
        "height": 200,
        "mark": "bar",
        "encoding": {
            "x": {"field": "date", "timeUnit": "monthdate", "axis": {"labelAngle": 0}, "title": "Date/Month"},
            "y": {"field": "amount_of_points", "type": "quantitative", "title": "Amount of points"}
        }
    }
});

export default HistogramSelectionChart;
import React from 'react'
import axios from 'axios'
import {useAuthentication} from "./authentication";
import {SHMNEXT_API_URL} from "../config";
import moment from "moment/moment";


const UniqLocationContext = React.createContext({
    getUniqLocationGroupByInfrastructure: (infrastructure) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    addUniqLocationGroupToInfrastructure: (infrastructure, uniq_location_group) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    addUniqLocationToGroup: (uniq_location_group, uniq_location) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getUniqLocationById: (id) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getUniqLocationByDataset: (id) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getUniqLocationsByGroup: (id) => {
        return new Promise((resolve, _) => {
            resolve([])
        })
    },
    getHistogramOfUniqLocation: (loc, minimumSNR) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
    updateLocation: (loc) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    },
    updateGeometry: (loc, geometry) => {
        return new Promise((resolve, _) => {
            resolve({})
        })
    }
})

const UniqLocationProvider = (props) => {

    const {tokens: {access_token}, logout} = useAuthentication()

    const getUniqLocationGroupByInfrastructure = (infrastructure) => {
        return new Promise((resolve, reject) => {
            return axios.get(infrastructure["_links"]["uniq_location_groups"]["href"], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                if ("_embedded" in r.data) {
                    resolve(r.data['_embedded']['uniq_location_groups'])
                } else {
                    resolve([])
                }
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const getUniqLocationById = (id) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/uniq_locations/id/${id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        });
    }


    const getUniqLocationByRelatedObject = (dataset) => {
        return new Promise((resolve, reject) => {
            return axios.get(dataset['_links']['uniq_location']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        });
    }


    const getUniqLocationsByGroup = (dataset) => {
        return new Promise((resolve, reject) => {
            return axios.get(dataset['_links']['uniq_locations']['href'], {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                if ("_embedded" in r.data) {
                    resolve(r.data['_embedded']['uniq_locations'])
                } else {
                    resolve([])
                }

            })
                .catch((error) => {
                    logout()
                    reject()
                });
        });
    }


    const getHistogramOfUniqLocation = (uniqLocation, filters) => {
        return new Promise((resolve, reject) => {
            return axios.get(`${SHMNEXT_API_URL}/raw_uniq_measurements/histogram/${uniqLocation.id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                },
                params: {
                    'snr': filters.snr,
                    'start_moment': moment(filters.start_moment || null).isValid() ? moment(filters.start_moment).format("YYYY-MM-DD") : undefined,
                    'stop_moment': moment(filters.stop_moment || null).isValid() ? moment(filters.stop_moment).format("YYYY-MM-DD") : undefined
                }
            }).then(r => {
                const histogram = r.data['histogram'];
                const histogramAsObjects = Object.keys(histogram).reduce((acc, date) => {
                    return [...acc, {"date": date, "amount_of_points": histogram[date]}]
                }, [])
                resolve(histogramAsObjects)
            })
                .catch((error) => {
                    logout()
                    reject()
                });
        })
    }

    const addUniqLocationGroupToInfrastructure = (infrastructure, uniq_location_group) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniq_locations/group/infrastructure/id/${infrastructure.id}`, uniq_location_group, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve();
            })
                .catch((error) => {
                    console.log("ERROR")
                    reject();
                });
        })
    }

    const addUniqLocationToGroup = (uniq_location_group, uniq_location) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniq_locations/by-group/id/${uniq_location_group.id}`, uniq_location, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve();
            })
                .catch((error) => {
                    console.log("ERROR")
                    reject();
                });
        })
    }

    const updateGeometry = (location, geometry) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniq_locations/id/${location.id}/geometry`, geometry, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve();
            })
                .catch((error) => {
                    console.log("ERROR")
                    reject();
                });
        })
    }

    const updateLocation = (location) => {
        return new Promise((resolve, reject) => {
            return axios.post(`${SHMNEXT_API_URL}/uniq_locations/id/${location.id}`, location, {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            }).then(r => {
                resolve(r.data);
            })
                .catch((error) => {
                    console.log("ERROR")
                    reject();
                });
        })
    }

    return (
        <UniqLocationContext.Provider
            value={{
                addUniqLocationGroupToInfrastructure,
                getUniqLocationGroupByInfrastructure,
                getUniqLocationById,
                addUniqLocationToGroup,
                getUniqLocationByRelatedObject,
                getHistogramOfUniqLocation,
                getUniqLocationsByGroup,
                updateGeometry,
                updateLocation
            }} {...props} />
    )

}
const useUniqLocations = () => React.useContext(UniqLocationContext)
export {UniqLocationProvider, useUniqLocations}


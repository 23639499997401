import {EuiBasicTable, EuiText, EuiSpacer, EuiHorizontalRule, formatDate} from "@elastic/eui"
import {useEffect, useState} from "react";
import {useRawUniqMeasurements} from "../../context/rawUniqMeasurements";

const RawUniqMeasurementTable = ({location, filters, onMeasurementSelect}) => {

    const {getRawUniqMeasurementsByUniqLocationPaged} = useRawUniqMeasurements();

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
        pageSizeOptions: [10, 100],
        showPerPageOptions: true
    })
    const [total, setTotal] = useState(0)
    const [page, setCurrentPage] = useState([])

    useEffect(() => {
        getRawUniqMeasurementsByUniqLocationPaged(location, pagination.pageIndex, pagination.pageSize, filters).then(page => {
            const empty = page['_embedded'] === undefined;
            if (empty) {
                setCurrentPage([])
            } else {
                setCurrentPage(page['_embedded']['raw_uniq_measurements'])
            }
            setTotal(page['page']['totalElements'])
        })

    }, [pagination, filters, location])

    const columns = [
        {
            field: 'id',
            name: 'Identifier',
        },
        {
            field: 'snrch1',
            name: 'SNR of channel 1',
        },
        {
            field: 'snrch2',
            name: 'SNR of channel 2',
        },
        {
            field: 'snrch3',
            name: 'SNR of channel 3',
        }, {
            field: 'snrch4',
            name: 'SNR of channel 4',
        },
        {
            field: 'measured_at',
            name: 'Measured at',
            render: (item) => formatDate(item)
        }

    ]

    const resultsCount =
        (
            <>
                <strong>
                    {pagination.pageSize * pagination.pageIndex + 1}-{pagination.pageSize * pagination.pageIndex + pagination.pageSize}
                </strong>{' '}
                of {total}
            </>
        );

    const getRowProps = (item) => {
        const {id} = item;
        return {
            'data-test-subj': `row-${id}`, className: 'customRowClass', onClick: () => {
                onMeasurementSelect(id)
            },
        };
    };

    const getCellProps = (item, column) => {
        const {id} = item;
        const {field} = column;
        return {
            className: 'customCellClass', 'data-test-subj': `cell-${id}-${field}`, textOnly: true,
        };
    };

    return (
        <div>
            <EuiText size="xs">
                Showing {resultsCount} <strong>Raw UniQ Measurements</strong>
            </EuiText>
            <EuiSpacer size="s"/>
            <EuiHorizontalRule margin="none" style={{height: 2}}/>
            <EuiBasicTable
                tableCaption="Demo for EuiBasicTable with pagination"
                items={page}
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                pagination={{...pagination, totalItemCount: total}}
                onChange={({page}) => {
                    setPagination({...pagination, pageIndex: page.index, pageSize: page.size})
                }}
            />
        </div>
    )
}

export default RawUniqMeasurementTable;
import React from 'react'
import {VegaLite} from "react-vega";

import euiThemeVars
    from "@elastic/eui/dist/eui_theme_dark.json"

const VegaRenderPanel = (props) => {

    const vega = props.output.content;

    return (

        <VegaLite spec={JSON.parse(vega)} config={{
            axis: {
                domainColor: euiThemeVars.euiColorChartLines,
                gridColor: euiThemeVars.euiColorChartLines,
                tickColor: euiThemeVars.euiColorChartLines,
            },
            legend: {
                strokeColor: 'transparent'
            },
            background: 'transparent',
            mark: {color: '#54B399'},
            style: {
                'group-title': {
                    fill: euiThemeVars.euiColorDarkestShade,
                },
                'guide-label': {
                    fill: euiThemeVars.euiColorDarkShade,
                },
                'guide-title': {
                    fill: euiThemeVars.euiColorDarkestShade,
                },
                'group-subtitle': {
                    fill: euiThemeVars.euiColorDarkestShade,
                },
            },
            title: {
                color: euiThemeVars.euiColorDarkestShade,
            },
        }}/>
    )


}

export default VegaRenderPanel;
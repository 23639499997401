import {
    EuiBadge,
    EuiIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiPagination,
    EuiPopoverTitle,
    EuiFilterButton,
    EuiPanel,
    EuiSearchBar,
    EuiPopover,
    EuiLink,
    EuiSelect,
    EuiSelectable,
    EuiSpacer,
    EuiText,
    EuiFilterGroup,
    EuiTextColor,
    EuiTitle,
    useGeneratedHtmlId
} from "@elastic/eui"
import {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";

const PostProcessingList = (props) => {
    const processes = props.postProcesses;
    const [currentPage, setCurrentPage] = useState(0);
    const [sortType, setSortType] = useState("latest")
    const [types, setTypes] = useState([{label: "QBF", id: 'qbf', checked: 'on'}, {
        label: "Clustering",
        id: 'clustering',
        checked: 'on'
    }, {
        label: "Reporting",
        id: 'report',
        checked: 'on'
    }]);

    const timeoutRef = useRef();
    const history = useHistory();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [withLoading, setWithLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    const onButtonClick = () => {
        if (withLoading && !isPopoverOpen) {
            setIsLoading(true);
            timeoutRef.current = setTimeout(() => {
                setIsLoading(false);
            }, 1500);
        }
        setIsPopoverOpen(!isPopoverOpen);
    };

    const closePopover = () => {
        setIsPopoverOpen(false);
    };

    const filterGroupPopoverId = useGeneratedHtmlId({
        prefix: 'filterGroupPopover',
    });

    const button = (
        <EuiFilterButton
            iconType="arrowDown"
            onClick={onButtonClick}
            isSelected={isPopoverOpen}
            numFilters={types.filter((item) => item.checked !== 'off').length}
            hasActiveFilters={!!types.find((item) => item.checked === 'on')}
            numActiveFilters={types.filter((item) => item.checked === 'on').length}
        >
            Process types
        </EuiFilterButton>
    );

    const applySortAndFilters = (processes) => {

        const sortAlphabetically = (a, b) => a.name.localeCompare(b.name)
        const sortByCreated = (a, b) => new Date(b.created_at) - new Date(a.created_at);

        const sortFunction = sortType === 'alphabetical' ? sortAlphabetically : sortByCreated

        const sorted = processes.sort(sortFunction)
        return sorted.filter(process => new Set(types.filter(t => t.checked).map(t => t.id)).has(process.serviceCall.serviceName))
    }

    const processesToShow = applySortAndFilters(processes);

    return (<>
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem><EuiSearchBar/></EuiFlexItem>
                <EuiFlexItem>
                    <EuiPagination
                        aria-label="PostProcess pagination"
                        pageCount={Math.ceil(processesToShow.length / 6)}
                        activePage={currentPage}
                        onPageClick={(activePage) => setCurrentPage(activePage)}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="l"/>
            <EuiFlexGroup>
                <EuiFlexItem grow={4}>
                    <EuiPanel hasShadow={true} hasBorder={false}>
                        <EuiTextColor color="subdued"><h3 key={5}>Type of Data Processing</h3></EuiTextColor>
                        <EuiSpacer size="s"/>
                        <EuiFilterGroup>
                            <EuiPopover
                                id={filterGroupPopoverId}
                                button={button}
                                isOpen={isPopoverOpen}
                                closePopover={closePopover}
                                panelPaddingSize="none"
                            >
                                <EuiSelectable
                                    allowExclusions={false}
                                    searchable
                                    searchProps={{
                                        placeholder: 'Filter list',
                                        compressed: true,
                                    }}
                                    aria-label="Process types"
                                    options={types}
                                    onChange={(newOptions) => setTypes(newOptions)}
                                    isLoading={isLoading}
                                    loadingMessage="Loading filters"
                                    emptyMessage="No filters available"
                                    noMatchesMessage="No filters found"
                                >
                                    {(list, search) => (
                                        <div style={{width: 300}}>
                                            <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
                                            {list}
                                        </div>
                                    )}
                                </EuiSelectable>
                            </EuiPopover>
                        </EuiFilterGroup>
                        <EuiSpacer size="s"/>
                        <EuiTextColor color="subdued"><h3 key={5}>Sort by</h3></EuiTextColor>
                        <EuiSpacer size="s"/>
                        <EuiSelect
                            options={[
                                {value: 'latest', text: 'Latest'},
                                {value: 'alphabetical', text: 'Name'}
                            ]}
                            onChange={(e) => setSortType(e.target.value)}
                            value={sortType}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={8}>
                    <EuiFlexGroup responsive={false} direction="column">
                        {processesToShow.slice(currentPage * 6, currentPage * 6 + 6).map((process, index) => (
                            <EuiFlexItem grow={false} key={index}>
                                <EuiPanel grow={false} hasShadow={false} hasBorder={true}>
                                    <EuiFlexGroup direction="row" alignItems="center">
                                        <EuiFlexItem grow={false}>
                                            <EuiLink
                                                color="primary"
                                                onClick={() => history.push(`postprocesses/${process.id}`)}>
                                                <EuiTitle size="m">
                                                    <h1>{process.name}</h1>
                                                </EuiTitle></EuiLink>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                wrap
                                                responsive={false}
                                                gutterSize="xs"
                                                style={{maxWidth: '300px'}}
                                            >
                                                {process.tags.map((badge) => (
                                                    <EuiFlexItem grow={false} key={badge}>
                                                        <EuiBadge color={"primary"}>{badge}</EuiBadge>
                                                    </EuiFlexItem>
                                                ))}
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>

                                    <EuiHorizontalRule/>

                                    <EuiText>
                                        Type: {process.serviceCall.serviceName || "Unnamed"}<br/>
                                        Completed: <EuiIcon type={process.processing ? "cross" : "check"}
                                                            color="success"/> <br/>
                                        {/*Description: {process.description || "No description available"}*/}
                                    </EuiText>

                                </EuiPanel>
                            </EuiFlexItem>))
                        }
                    </EuiFlexGroup>
                </EuiFlexItem>

            </EuiFlexGroup>


        </>
    )
}
export default PostProcessingList